export interface IForm {

    title?: string;
    subTitle?: string;
    description?: string;
    type?: string;
    inputs?: IFormInput[];
    textareas?: IFormTextarea[];
    checkboxes?: IFormCheckbox[];
    recipient?: any;

}

export interface IFormInput
{
    name: string;
    label?: string;
    type: string;
    required: boolean;
    disabled?: boolean;
    value?: string|number;
    placeholder?: string;
}

export interface IFormCheckbox
{
    name: string;
    label?: string;
    value?: boolean;
}

export interface IFormTextarea
{
    name: string;
    label?: string;
    required: boolean;
    value?: string;
    placeholder?: string;
    rows?: number;
}

export class IFormTransferAuthor {
    name!: string;
    email!: string;
}

export class FormTransfer
{
    title!: string;
    // sender?: IFormTransferAuthor;
    // recipient?: IFormTransferAuthor;
    recipientName?: string;
    recipientMail?: string;
    type?: string;
    nachrichtenfelder!: any[];

    constructor(data?: any, recipient?: IFormTransferAuthor)
    {
        if (data)
        {
            this.title = data.title;
            this.type = data.type ? data.type.toLowerCase() : null;
            this.nachrichtenfelder = data.nachrichtenfelder ? data.nachrichtenfelder : [];
        }

        if (recipient)
        {
            this.recipientName = recipient.name;
            this.recipientMail = recipient.email;
        }
        // this.sender = sender;
        // this.recipient = recipient;
    }

    static create(form: IForm, result: any, recipient?: IFormTransferAuthor)
    {
        // console.log('[FormTransfer] transfer', form, result);

        if (!recipient) {
            recipient = form.recipient;
        }

        const formTransfer = new FormTransfer(form, recipient);

        if (form.inputs)
        {
            Object.keys(result).forEach((key) => {

                // @ts-ignore
                const input = form.inputs.find((input) => {
                    return input.name == key;
                });

                if (input)
                {
                    formTransfer.nachrichtenfelder.push({
                        label: input.label,
                        key: key,
                        value: result[key],
                        type: input.type
                    });
                }
            });
        }

        if (form.checkboxes)
        {
            Object.keys(result).forEach((key) => {

                // @ts-ignore
                const input = form.checkboxes.find((input) => {
                    return input.name == key;
                });

                if (input)
                {
                    formTransfer.nachrichtenfelder.push({
                        label: input.label,
                        key: key,
                        value: result[key] ? 'ja' : 'nein',
                        type: 'boolean'
                    });
                }
            });
        }

        if (form.textareas)
        {
            Object.keys(result).forEach((key) => {

                // @ts-ignore
                const input = form.textareas.find((input) => {
                    return input.name == key;
                });

                if (input)
                {
                    formTransfer.nachrichtenfelder.push({
                        label: input.label ? input.label : input.placeholder,
                        key: key,
                        value: result[key],
                        type: 'long_text'
                    });
                }
            });
        }

        return formTransfer;
    }
}
