import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_document = _resolveComponent("persona-document")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "persona__grid" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.grid.rows, (row, rowKey) => {
        return (_openBlock(), _createBlock(_component_ion_row, {
          class: ["persona__grid-row ", {'ion-margin-bottom': _ctx.grid.rows.length > 1, 'ion-align-items-center': row.align === 'center', 'ion-align-items-end': row.align === 'end', 'ion-justify-content-center': row.justify === 'center', 'ion-justify-content-end': row.justify === 'end'}],
          key: rowKey
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(row.cols, (col, colKey) => {
              return (_openBlock(), _createBlock(_component_ion_col, {
                class: ["persona__grid-col", _ctx.colClasses(col)],
                offset: col.offset,
                size: col.size,
                key: colKey
              }, {
                default: _withCtx(() => [
                  (col.documents && col.documents.length)
                    ? (_openBlock(), _createBlock("div", _hoisted_1, [
                        _createVNode(_component_persona_document, {
                          documents: col.documents
                        }, null, 8, ["documents"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["class", "offset", "size"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _: 1
  }))
}