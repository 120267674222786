
import {defineComponent, toRefs} from 'vue';
import {Page} from '@/models/page.model';
import {IonRow, IonCol, modalController} from '@ionic/vue';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import AssetService from '@/services/asset.service';
import PersonaAsset from '@/components/asset/Asset.vue';

export default defineComponent( {
    name: 'MapAsset',
    components: {
        IonRow,
        IonCol,
        PersonaAsset
    },
    props: {
        mapAsset: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const { mapAsset } = toRefs(props);

        const openModal = async (modalPage?: Page) =>
        {
            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: modalPage
                },
            });

            return modal.present()
        };

        const map = AssetService.buildMap(mapAsset.value, openModal);

        const onClick = (area) => {
            // console.log('[MapAsset] area', area);
            if (area.modal)
            {
                openModal(area.modal);
            }
        }

        return { map, onClick };
    }
})
