
import {defineComponent, toRefs, ref} from 'vue';
import PersonaHeader from '@/components/header/Header.vue';
import {IonRow, IonCol, IonText, modalController} from '@ionic/vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import PersonaMapAsset from '@/components/map-asset/MapAsset.vue';
import PersonaImageGallery from '@/components/image-gallery/ImageGallery.vue';
import PersonaImageGrid from '@/components/image-grid/ImageGrid.vue';
import PersonaGrid from '@/components/grid/Grid.vue';
import PersonaDocument from '@/components/document/Document.vue';
import {Page} from '@/models/page.model';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import AssetService from '@/services/asset.service';

export default defineComponent({
    name: 'Content',
    components: {
        PersonaHeader,
        PersonaAsset,
        PersonaMapAsset,
        PersonaImageGallery,
        PersonaImageGrid,
        PersonaGrid,
        PersonaDocument,
        IonRow,
        IonText,
        IonCol
    },
    props: {
        page: {
            type: Page,
            required: true
        },
        displayHeader: {
            type: Boolean,
            default: true
        }
    }
});
