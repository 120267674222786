import {createI18n, LocaleMessages, VueMessageType} from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType>
{
    const locales = require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages<VueMessageType> = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            const subKey = key.replace(locale, '').replace('.json', '').replace('./','').replace('.','');
            if (messages[locale])
            {
                if (subKey.length)
                {
                    messages[locale][subKey] = locales(key);
                }
                else{
                    messages[locale] = {...messages[locale], ...locales(key)};
                }
            }
            else{
                if (subKey.length)
                {
                    messages[locale] = {};
                    messages[locale][subKey] = locales(key);
                }
                else {
                    messages[locale] = locales(key);
                }

            }

        }
    });

    return messages;
}

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})
