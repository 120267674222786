import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "persona__document"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 2,
  class: "persona__document-assets ion-margin-bottom"
}
const _hoisted_4 = {
  key: 3,
  class: "persona__document-assets ion-margin-bottom"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_card = _resolveComponent("persona-card")!
  const _component_persona_button = _resolveComponent("persona-button")!
  const _component_persona_list = _resolveComponent("persona-list")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_persona_paragraph = _resolveComponent("persona-paragraph")!
  const _component_persona_asset = _resolveComponent("persona-asset")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_ctx.documents && _ctx.documents.length)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_ion_grid, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.documents, (document, documentKey) => {
                  return (_openBlock(), _createBlock("div", {
                    class: "full-width",
                    key: documentKey
                  }, [
                    (document.entries && document.entries.length)
                      ? (_openBlock(), _createBlock("div", _hoisted_2, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(document.entries, (entry, entryKey) => {
                            return (_openBlock(), _createBlock("div", { key: entryKey }, [
                              _createVNode(_component_v_switch, {
                                case: entry.type
                              }, {
                                card: _withCtx(() => [
                                  _createVNode(_component_persona_card, { card: entry }, null, 8, ["card"])
                                ]),
                                button: _withCtx(() => [
                                  _createVNode(_component_persona_button, { button: entry }, null, 8, ["button"])
                                ]),
                                list: _withCtx(() => [
                                  _createVNode(_component_persona_list, { list: entry }, null, 8, ["list"])
                                ]),
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(entry.type) + " nicht verfügbar ", 1)
                                ]),
                                _: 2
                              }, 1032, ["case"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (document.paragraphs && document.paragraphs.length)
                      ? (_openBlock(), _createBlock(_component_persona_paragraph, {
                          key: 1,
                          class: "ion-margin-bottom",
                          paragraphs: document.paragraphs
                        }, null, 8, ["paragraphs"]))
                      : _createCommentVNode("", true),
                    (document.assets && document.assets.length)
                      ? (_openBlock(), _createBlock("div", _hoisted_3, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(document.assets, (asset, assetKey) => {
                            return (_openBlock(), _createBlock(_component_persona_asset, {
                              asset: asset,
                              key: assetKey
                            }, null, 8, ["asset"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (document.list && document.list.items.length)
                      ? (_openBlock(), _createBlock("div", _hoisted_4, [
                          (document.list.type === 'unordered')
                            ? (_openBlock(), _createBlock("ul", _hoisted_5, [
                                (_openBlock(true), _createBlock(_Fragment, null, _renderList(document.list.items, (item, itemKey) => {
                                  return (_openBlock(), _createBlock("li", { key: itemKey }, [
                                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(item.paragraphs, (paragraphs, paragraphKey) => {
                                      return (_openBlock(), _createBlock("span", { key: paragraphKey }, [
                                        _createVNode(_component_persona_paragraph, { paragraphs: paragraphs }, null, 8, ["paragraphs"])
                                      ]))
                                    }), 128))
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}