import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import i18n from './i18n'
import {IonicVue} from '@ionic/vue';
import VueGtag from 'vue-gtag';
import Tabs from 'vue3-tabs';
import mitt from 'mitt';
// Added by the CLI
import './registerServiceWorker';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import './theme/core/font.scss';
import './theme/core/scrims.scss';

const app = createApp(App)
.use(i18n)
.use(Tabs)
.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID
    }
})
.use(IonicVue, {
    rippleEffect: true,
    mode: 'ios',
    backButtonText: ''
})
.use(router);

const emitter = mitt();

router.isReady().then(() => {
    app.config.globalProperties.emitter = emitter;
    app.mount('#app');
});

