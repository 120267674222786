import {Entry} from 'contentful';
import {Asset} from '@/models/asset.model';
import {IContentfulEntity} from '@/models/contentfulEntity.model';
import {Document} from '@/models/document.model';
import UtilityService from '@/services/utility.service';
import {Grid} from '@/models/grid.model';

export interface IPage
{
    id: string;
    title: string;
    titleHyphen?: string;
    tabTitle: string;
    subTitle: string;
    path: string;
    grid?: Grid;
    cover: Asset;
    image?: Asset;
    mapAsset?: any;
    imageGallery?: any;
    imageGrid?: any;
    isForm?: boolean;
    formType?: string;
    layout?: string;
    documents?: Document[];
}

export class Page implements IPage, IContentfulEntity
{
    id!: string;
    title!: string;
    titleHyphen?: string;
    tabTitle!: string;
    subTitle!: string;
    path!: string;
    grid?: Grid;
    cover!: Asset;
    image?: Asset;
    mapAsset?: any;
    imageGallery?: any;
    imageGrid?: any;
    isForm?: boolean;
    hideInTabs?: boolean;
    formType?: string;
    layout?: string;
    recipientName?: string;
    recipientEmail?: string;
    documents?: Document[];

    static transferContentfulEntry(entry?: Entry<any>): Page|any
    {
        // console.log('[Page] entry', entry);
        if (entry!.fields)
        {
            const page = new Page();

            page.id = entry!.fields.id;
            page.title = entry!.fields.titel;
            page.tabTitle = entry!.fields.tabTitel;
            page.subTitle = entry!.fields.subtitel;
            page.path = entry!.fields.slug;
            page.hideInTabs = false;

            if (page!.title)
            {
                UtilityService.hyphen(page.title).then(t => page.titleHyphen = t);
            }

            if (!page.tabTitle)
            {
                page.tabTitle = page.title;
            }

            if (entry!.fields.titelbild)
            {
                page.cover = Asset.transferContentfulEntry(entry!.fields.titelbild);
            }

            if (entry!.fields.raster)
            {
                page.grid = Grid.transferContentfulEntry(entry!.fields.raster);
                // console.log('page.grid', page.grid)
            }

            if (entry!.fields.bild)
            {
                page.image = Asset.transferContentfulEntry(entry!.fields.bild);
            }

            if (entry!.fields.bildergalerie && entry!.fields.bildergalerie.fields)
            {
                // console.log('[PageModel] entry!.fields.fields.bildergalerie', entry!.fields.bildergalerie)
                const galery: any = {images: []};
                for (const image of entry!.fields.bildergalerie.fields.bilder)
                {
                    galery.images.push(Asset.transferContentfulEntry(image));
                }

                galery.loop = entry!.fields.bildergalerie.fields.schleife;
                galery.pagination = entry!.fields.bildergalerie.fields.pagination;
                if (entry!.fields.bildergalerie.fields.effekt)
                {
                    galery.effect = entry!.fields.bildergalerie.fields.effekt.toLowerCase();
                }

                if (entry!.fields.bildergalerie.fields.paginationTyp)
                {
                    switch (entry!.fields.bildergalerie.fields.paginationTyp)
                    {
                        case 'Punkte':
                            galery.paginationType = 'bullets';
                            break;
                        case 'Scrollbar':
                            galery.paginationType = 'scrollbar';
                            break;
                    }
                }

                page.imageGallery = galery;
            }

            const mapAsset = Page.setupMapAsset(entry!.fields.mapAsset);
            if (mapAsset)
            {
                page.mapAsset = mapAsset;
            }

            if (entry!.fields.empfaengerName)
            {
                page.recipientName = entry!.fields.empfaengerName;
            }
            if (entry!.fields.empfaengerEmail)
            {
                page.recipientEmail = entry!.fields.empfaengerEmail;
            }

            if (entry!.fields.bildraster && entry!.fields.bildraster.fields)
            {
                // console.log('entry!.fields.bildraster', entry!.fields.bildraster)
                const images: any = [];
                for (const image of entry!.fields.bildraster.fields.bilder)
                {
                    let asset = Asset.transferContentfulEntry(image.fields.bild);
                    if (image.fields.ausrichtung)
                    {
                        asset = Asset.setAlignment(asset, image.fields.ausrichtung);
                    }

                    // console.log('entry!.fields.bild', image.fields.modal)

                    if (image.fields.modal)
                    {
                        const modal = Page.transferContentfulEntry(image.fields.modal);
                        if (modal)
                        {
                            modal.layout = 'modal';
                            asset.modal = modal;
                        }
                    }

                    images.push(asset);
                }

                page.imageGrid = {
                    images: images
                };

                // console.log('entry!.fields.bildraster', entry!.fields.bildraster)
                // console.log('page.imageGrid', page.imageGrid)
            }

            if (entry!.fields!.inhalt)
            {
                const documents: Document[] = [];

                switch (entry!.fields!.inhalt.nodeType)
                {
                    case 'document':
                        entry!.fields!.inhalt.content.forEach((contentEle) => {
                            documents.push(Document.transferContentfulEntry(contentEle));
                        })
                        break;
                }

                page.documents = documents;
            }

            if (entry!.fields.formulartyp)
            {
                page.isForm = true;
                page.formType = entry!.fields.formulartyp;
            }

            if (entry!.sys!.contentType!.sys!.id)
            {
                switch (entry!.sys!.contentType!.sys!.id)
                {
                    default:
                    case 'seite':
                        page.layout = 'default';
                        break;

                    case 'startseite':
                    case 'titelseite':
                        page.layout = 'cover';
                        page.hideInTabs = true;
                        break;

                    case 'formular':
                        page.layout = 'form';
                        break;
                }
            }

            // console.log('[Page] entry', page);

            return page;
        }
    }

    static setupMapAsset(mapAssetData)
    {
        if (mapAssetData && mapAssetData.fields)
        {
            const mapAsset: any = {};

            mapAsset.hasAreaImages = false;
            mapAsset.hasMarginTop = true;
            // console.log('hyperlink  mapAssetData',  mapAssetData)
            if (mapAssetData.fields.bild)
            {
                mapAsset.image = Asset.transferContentfulEntry(mapAssetData.fields.bild);
            }

            if (mapAsset.image && mapAssetData.fields.ausrichtung)
            {
                mapAsset.image = Asset.setAlignment(mapAsset.image, mapAssetData.fields.ausrichtung);
            }


            if (mapAssetData.fields.abstandNachOben !== undefined)
            {
                mapAsset.hasMarginTop = mapAssetData.fields.abstandNachOben;
            }

            const areas: any = [];
            if (mapAssetData.fields.bereiche)
            {
                for (const act of mapAssetData.fields.bereiche)
                {
                    if (act.fields)
                    {
                        // console.log('act.fields.reiheMitBereichen', act.fields)

                        if (act.fields.bereiche)
                        {
                            const area = {areas: []};

                            for (const actSubBereich of act.fields.bereiche)
                            {
                                const subArea = Page.setupMapAssetArea(actSubBereich);
                                // @ts-ignore
                                area.areas.push(subArea);
                            }
                            areas.push(area);
                        }
                        else{
                            const area = Page.setupMapAssetArea(act, mapAsset);
                            areas.push(area);
                        }
                    }
                }
            }

            if (mapAssetData.fields.modal)
            {
                // console.log('map asset modal', entry!.fields.mapAsset.fields.modal)
                mapAsset.modal = Page.transferContentfulEntry(mapAssetData.fields.modal);
                mapAsset.modal.layout = 'modal';
            }
            mapAsset.areas = areas;

            return mapAsset;
        }
    }

    static setupMapAssetArea(act, mapAsset?)
    {
        let modal: any = {};
        if (act.fields.modal)
        {
            modal = Page.transferContentfulEntry(act.fields.modal);
            if (modal)
            {
                modal.layout = 'modal';
            }
        }

        const area: any = {coordinates: act.fields.koordinaten, modal: act.fields.modal ?  modal : null};

        if (act.fields.form)
        {
            switch (act.fields.form)
            {
                case 'kreis':
                case 'Kreis':
                    area.shape = 'circle';
                    break;
                case 'Rechteck':
                    area.shape = 'rect';
                    break;
            }
        }

        if (act.fields.bild)
        {
            area.image = Asset.transferContentfulEntry(act.fields.bild);
            if (mapAsset)
            {
                mapAsset.hasAreaImages = true;
            }
        }

        return area;
    }
}
