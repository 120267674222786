// @ts-ignore
import {getData, isBrowser, isBrowser as testIsInBrowser, isFunction, isUndefined, PlainObject} from '@labor-digital/helferlein';

declare global
{
    interface Window {
        _etracker?: any;
        et_ClickEvent?: any;
        et_eC_Wrapper?: any;
        _bt?: any;
        _btNoWs?: any;
        _dcLaunch?: any;
    }
}

export class EtrackerService
{
    accountCode: string | null = null;
    isInitialPageChange = true;

    public initialize(): void
    {
        this.accountCode = this.getAccountCode();
    }

    public trackPageChange(pageName: string): void
    {
        // The first page change is already tracked by etracker -> so we have to ignore it here
        // if (this.isInitialPageChange) {
        //     this.isInitialPageChange = false;
        //     return;
        // }

        if (this.accountCode && isBrowser() && !isUndefined(window.et_eC_Wrapper)) {
            // console.log('[EtrackerService::trackPageChange]', pageName);

            // eslint-disable-next-line @typescript-eslint/camelcase
            window.et_eC_Wrapper({et_et: this.accountCode, et_pagename: pageName});
        }
    }

    protected trackEvent(event): void
    {
        if (isBrowser() && !isUndefined(window._etracker)) {
            window._etracker.sendEvent(event);
        }
    }

    /**
     * Internal helper to resolve the account code from the etracker loader script tag
     * @protected
     */
    protected getAccountCode(): string | null
    {
        if (isBrowser && this.accountCode === null) {
            const el = document.getElementById('_etLoader');
            if (el) {
                this.accountCode = getData(el, 'secure-code', null);
            }
        }

        return this.accountCode;
    }

}
export default new EtrackerService();
