import {
    Account,
    AuthError,
    Configuration,
    InteractionRequiredAuthError,
    Logger,
    LogLevel,
    UserAgentApplication
} from 'msal';

import useEmitter from '@/composables/useEmitter';
import {EVENTS} from '@/models/app.model';

import { event } from 'vue-gtag';
import AnalyticsService from '@/services/analytics.service';

export class AuthService
{
    protected _msal: UserAgentApplication;
    protected _msalConfig: any;
    protected _account?: Account|any;
    protected _token?: string;
    protected _scope: Array<string> = [];
    protected _initDone: boolean = false;

    constructor()
    {
        this._msalConfig = this.getMsalConfig();
        this._msal = new UserAgentApplication(this._msalConfig);

        this._scope = [process.env.VUE_APP_MSAL_SCOPE];

        this.init();
    }

    public async init(): Promise<void>
    {
        // console.log('[AuthService::init]', this._scope);

        this._initDone = true;

        this._msal.getAuthorityInstance();
        const accounts = this._msal.getAllAccounts();

        if (accounts.length === 1) {
            this._account = accounts[0];
        } else if (accounts.length > 1) {
            this.logout();
        }

        this._msal.handleRedirectCallback((err, response) => {
            if (err || !response) {
                if (!err) {
                    err = new AuthError('1', 'Redirect callback did not receive a valid response');
                }

                // this._app.state.lastLoginError = {error: err.errorMessage, desc: err.errorMessage.replace(/\+/g, ' ')};
                this.logout();
                return;
            }

            // this._token = response.accessToken;
            this._account = response.account;

            // console.log('[AuthService::init] handleRedirectCallback', response);
        });

        // if (!this._account) {
        //     return Promise.resolve();
        // }

        // return Promise.resolve();
        return this.refreshToken();
    }

    /**
     * Returns the JWT if it is known, undefined if not
     */
    public get token(): string | undefined
    {
        return this._token;
    }

    /**
     * Returns the account information about the current user or undefined if there is none
     */
    public get account(): Account | undefined
    {
        // console.log('[AuthService::isLoggedIn] account', this._account);
        return this._account;
    }

    /**
     * Redirects the user to the login flow
     * @param loginHint Optional e-mail address to be forwarded to the flow
     */
    public login(loginHint?: string): void
    {
        if (this._account) {
            return;
        }

        AnalyticsService.trackLoginEvent();

        this._msal.loginRedirect({
            loginHint: loginHint,
            scopes: this._scope,
            prompt: 'login'
        });
    }

    /**
     * True if the user is currently logged in
     */
    public get isLoggedIn(): boolean
    {
        // needed to test app in local network on surface tablet
        if (process.env.VUE_APP_CONTEXT === 'development' && window.location.origin !== process.env.VUE_APP_MSAL_REDIRECT_URL)
        {
            return true;
        }

        if (process.env.VUE_APP_CONTEXT === 'development' && process.env.VUE_APP_SKIP_LOGIN)
        {
            // return true;
        }

        return this._account !== undefined;
    }

    /**
     * Logs out the current user
     */
    public logout(): void
    {
        if (!this._account) {
            return;
        }

        this._account = undefined;
        this._token = undefined;
        this._msal.logout();

        AnalyticsService.trackLogoutEvent();

        window.location.replace('/');
    }

    /**
     * Refreshes the current token by requiring a new one from B2C
     */
    public async refreshToken(): Promise<void>
    {
        if (!this._account) {
            return Promise.resolve();
        }

        if (!this._initDone)
        {
            throw new Error('You can\'t refresh the token before executing the init() method!');
        }

        try {
            const response = await this._msal.acquireTokenSilent({
                account: this._account,
                scopes: this._scope,
                forceRefresh: false
            });

            // console.log('[AuthService::refreshToken] response', response);

            // In case the response from B2C server has an empty accessToken field
            // throw an error to initiate token acquisition
            if (!response.accessToken || response.accessToken === '')
            {
                throw new InteractionRequiredAuthError('1');
            }
            else {
                // this._msal.getLogger()?.info('access_token acquired at: ' + new Date().toString());
                this._token = response.accessToken;
            }

            const emitter = useEmitter();
            emitter.emit(EVENTS.ACCESS_TOKEN_READY, this._token);

        } catch (error) {
            console.log('[AuthService::refreshToken] error', error);
            if (error instanceof InteractionRequiredAuthError)
            {
                // fallback to interaction when silent call fails
                return this._msal.acquireTokenRedirect({
                    account: this._account,
                    scopes: this._scope
                });
            } else {
                // return this._app.emitError(error).then();
            }
        }
    }

    /**
     * Returns the content of the Authorization containing the user token
     */
    public get tokenHeader(): string
    {
        return this._token ? 'Bearer ' + this._token : '';
    }

    /**
     * Internal helper to generate the msal configuration
     *
     * @protected
     */
    protected getMsalConfig(): Configuration
    {
        // console.log('[AuthService::getMsalConfig] process.env', process.env);

        return {
            auth: {
                clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
                authority: 'https://login.microsoftonline.com/' + process.env.VUE_APP_MSAL_TENANT,
                redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URL,
                postLogoutRedirectUri: process.env.VUE_APP_MSAL_REDIRECT_URL + '/login',
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            },
            system: {
                ...this.makeLogger()
            }
        };
    }

    /**
     * Internal helper to create a debug logger instance if required by the config
     * @protected
     */
    protected makeLogger(): Logger
    {
        return new Logger((level: any, message: any, containsPii: any) => {
            if (containsPii) {
                return;
            }

            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
            }
        });
    }

}

export default new AuthService();
