import * as Contentful from 'contentful';
import {ContentfulClientApi} from 'contentful';

export class ContentfulProvider
{
    // @ts-ignore
    private client: ContentfulClientApi;

    constructor()
    {
        this.initClient();
    }

    /**
     * Inits the client
     *
     * @private
     */
    private initClient()
    {
        // console.log('[ContentfulProvider::initClient] env', process.env);

        if (process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN_DELIVERY_API)
        {
            let environment = 'master';
            if (process.env.VUE_APP_CONTEXT === 'development')
            {
                environment = 'prod_2022-02-14';
            }

            this.client = Contentful.createClient({
                space: process.env.VUE_APP_CONTENTFUL_SPACE,
                accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN_DELIVERY_API,
                environment
            });
        }
        else{
            // console.log('[ContentfulProvider] use fake client');

            // we need a client to use the parse function
            this.client = Contentful.createClient({
                space: 'persona',
                accessToken: 'persona'
            });
        }
    }

    /**
     * Gets the Client
     */
    getClient()
    {
        return this.client;
    }

    /**
     * Gets a collection of Entries
     *
     * @param query
     */
    getEntries(query?: any)
    {
        if (!query)
        {
            query = {limit: 600};
        }
        return this.client.getEntries(query);
    }

    /**
     * Synchronizes either all the content or only new content since last sync See Synchronization for more information.
     *
     * @param query
     */
    sync(query?: any)
    {
        return this.client.sync(query);
    }

    /**
     * Gets an Asset
     *
     * @param id
     * @param query
     */
    getAsset(id: string, query?: any)
    {
        return this.client.getAsset(id, query);
    }

    /**
     * Parse raw json data into collection of entry objects.Links will be resolved also
     *
     * @param entries
     */
    parseEntries(entries)
    {
        return this.client.parseEntries(entries);
    }
}

export default new ContentfulProvider();
