import {Entry} from 'contentful';
import {Asset} from '@/models/asset.model';
import {Document} from '@/models/document.model';

export class Col {

    id?: any;
    documents: any;
    size?: number;
    offset?: number;
    title?: string;
    align?: string;
    justify?: string;

    static transferContentfulEntry(entry?: Entry<any>): Col|any
    {
        const col = new Col();

        if (entry && entry!.fields)
        {
            const fields = entry.fields;

            col.id = entry!.sys.id;
            col.title = fields.titel;
            col.size = fields.groesse;
            col.offset = fields.versatz;

            if (fields!.inhalt)
            {
                const documents: Document[] = [];
                switch (fields!.inhalt.nodeType)
                {
                    case 'document':
                        entry!.fields!.inhalt.content.forEach((contentEle) => {
                            documents.push(Document.transferContentfulEntry(contentEle));
                        })
                        break;
                    default:
                        console.log('[Col] fields!.inhalt.nodeType', fields!.inhalt)
                        break;
                }

                col.documents = documents;
            }

            col.justify = 'start';
            if (fields!.vertikaleAusrichtung)
            {
                switch (fields!.vertikaleAusrichtung)
                {
                    default:
                    case 'Start':
                        col.justify = 'start';
                        break;
                    case 'Ende':
                        col.justify = 'end';
                        break;
                    case 'Mitte':
                        col.justify = 'center';
                        break;
                }
            }

            col.align = 'start';
            if (fields!.horizontaleAusrichtung)
            {
                // console.log('fields!.horizontaleAusrichtung', fields!.horizontaleAusrichtung)
                switch (fields!.horizontaleAusrichtung)
                {
                    default:
                    case 'Start':
                        col.align = 'start';
                        break;
                    case 'Ende':
                        col.align = 'end';
                        break;
                    case 'Mitte':
                        col.align = 'center';
                        break;
                }
            }
        }

        return col;
    }

}

export class Row
{
    id?: any;
    cols!: Col[];
    title?: string;
    align?: string;
    justify?: string;

    static transferContentfulEntry(entry?: Entry<any>): Row|any
    {
        const row = new Row();
        const cols = [];

        if (entry && entry!.fields)
        {
            const fields = entry.fields;

            row.id = entry!.sys.id;
            row.title = fields.titel;

            if (fields.spalten)
            {
                for(const actCol of fields.spalten)
                {
                    // console.log('grid actCol', actCol)
                    // @ts-ignore
                    cols.push(Col.transferContentfulEntry(actCol));
                }
            }

            row.align = 'start';
            if (fields!.vertikaleAusrichtung)
            {
                switch (fields!.vertikaleAusrichtung)
                {
                    default:
                    case 'Start':
                        row.align = 'start';
                        break;
                    case 'Ende':
                        row.align = 'end';
                        break;
                    case 'Mitte':
                        row.align = 'center';
                        break;
                }
            }

            row.justify = 'start';
            if (fields!.horizontaleAusrichtung)
            {
                // console.log('fields!.horizontaleAusrichtung', fields!.horizontaleAusrichtung)
                switch (fields!.horizontaleAusrichtung)
                {
                    default:
                    case 'Start':
                        row.justify = 'start';
                        break;
                    case 'Ende':
                        row.justify = 'end';
                        break;
                    case 'Mitte':
                        row.justify = 'center';
                        break;
                }
            }

            row.cols = cols;
        }

        return row;
    }

}


export class Grid
{
    id?: any;
    rows!: Row[];

    static transferContentfulEntry(entry?: Entry<any>): Grid|any
    {
        const grid = new Grid();
        const rows = [];

        if (entry && entry!.fields)
        {
            const fields = entry.fields;
            grid.id = entry!.sys.id;

            if (fields.zeilen)
            {
                for(const actRow of fields.zeilen)
                {
                    // @ts-ignore
                    rows.push(Row.transferContentfulEntry(actRow));
                }
            }
        }

        grid.rows = rows;

        return grid;
    }
}
