import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "persona__image-gallery" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_asset = _resolveComponent("persona-asset")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      effect: _ctx.gallery.effect,
      "slides-per-view": 1,
      pagination: _ctx.pagination,
      scrollbar: _ctx.scrollbar,
      modules: _ctx.modules,
      loop: _ctx.gallery.loop,
      onSwiper: _ctx.setSwiperInstance,
      onSlideChange: _ctx.onSlideChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.gallery.images, (image, imageKey) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            class: "swiper-no-swiping",
            key: imageKey
          }, {
            default: _withCtx(() => [
              _createVNode(_component_persona_asset, {
                asset: image,
                onClick: _ctx.nextSlide
              }, null, 8, ["asset", "onClick"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["effect", "pagination", "scrollbar", "modules", "loop", "onSwiper", "onSlideChange"])
  ]))
}