
import { defineComponent } from 'vue';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue';
import PersonaAsset from '@/components/asset/Asset.vue';

export default defineComponent({
    name: 'SkeletonCard',
    components: {
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonCardContent,
        PersonaAsset
    },
    props: {
        displaySubtitle: {
            type: Boolean,
            default: false
        }
    }
})
