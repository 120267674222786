import {Asset, IAsset} from '@/models/asset.model';
import {Entry} from 'contentful';
import {IDocument, Document} from '@/models/document.model';
import {IPage, Page} from '@/models/page.model';

export class Button
{
    id?: string;
    text?: string;
    icon?: string;
    fill?: string = 'default';
    shape?: string = 'round';
    size?: string = 'large';
    color?: string = 'primary';
    type: string = 'button';
    modal?: IPage;
    hasModal: boolean = false;

    static transferContentfulEntry(entry?: Entry<any>): Button|any
    {
        const button = new Button();

        // console.log('[Button] entry!.fields', entry!.fields);

        if (entry!.fields)
        {
            button.id = entry!.sys.id;

            if (entry!.fields.text)
            {
                button.text = entry!.fields.text;
            }

            if (entry!.fields.modal)
            {
                button.modal = Page.transferContentfulEntry(entry!.fields.modal);
                button.hasModal = true;
            }

            if (entry!.fields.icon)
            {
                switch (entry!.fields.icon)
                {
                    case 'Briefumschlag':
                        button.icon = 'mail';
                        break;
                    case 'Pfeil rechts':
                        button.icon = 'arrow-right';
                        break;
                    case 'Plus':
                        button.icon = 'plus';
                        break;
                    case 'Haken':
                        button.icon = 'check';
                        break;
                }
            }

            if (entry!.fields.farbe)
            {
                switch (entry!.fields.farbe)
                {
                    case 'Grün':
                        button.color = 'primary';
                        break;
                    case 'Grau':
                        button.color = 'secondary';
                        break;
                }
            }

            if (entry!.fields.groesse)
            {
                switch (entry!.fields.groesse)
                {
                    default:
                    case 'Groß':
                        button.size = 'large';
                        break;
                    case 'Klein':
                        button.size = 'small';
                        break;
                    case 'Normal':
                        button.size = '';
                        break;
                }


            }

            // console.log('[button] button', button);

            return button;
        }

    }
}
