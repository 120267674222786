
import {defineComponent, toRefs} from 'vue';
import {IonRow, IonCol, IonItem, IonCheckbox, IonLabel, IonText} from '@ionic/vue';
import {Form} from 'vee-validate';
import PersonaFormInput from '@/components/form/form-input/FormInput.vue';
import {FormTransfer, IForm} from '@/models/form.model';

import * as yup from 'yup';
import yupLocaleDe from '@/i18n/de.yupLocale';
import AlertService from '@/services/alert.service';
import StorageService from '@/services/storage.service';
import {STORAGE_KEYS} from '@/models/app.model';
import {useRouter} from 'vue-router';

yup.setLocale(yupLocaleDe);

export default defineComponent({
    name: 'FormLogin',
    components: {
        PersonaFormInput,
        Form,
        IonLabel,
        IonText,
        IonCheckbox,
        IonRow,
        IonCol,
        IonItem
    },
    props: {
        formTitle: {
            type: String,
            required: false
        },
        formType: {
            type: String,
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const { formType, formTitle } = toRefs(props);

        const router = useRouter();

        const schema = yup.object({
            email: yup.string().email().required().label('E-Mail Adresse'),
            password: yup.string().required().label('Passwort'),
        });

        const form: IForm = {};
        form.title = formTitle.value;
        form.type = formType.value;
        form.inputs = [
            {label: 'E-Mail Adresse', name: 'email', type: 'email', required: true, value: '', placeholder: '', disabled: false},
            {label: 'Passwort', name: 'password', type: 'password', required: true, value: '', placeholder: '', disabled: false}
        ];

        async function onSubmit(values: any, { resetForm })
        {
            console.log('onSubmit values', values);

            // TODO Validate via Bol
            if (values.email === 'dv@persona.de' && values.password === 'sP7Uj47WZdgweq2C')
            {
                localStorage.setItem(STORAGE_KEYS.TOKEN, 'sP7Uj47WZdgweq2C');

                router.push({ path: 'dashboard' }).then(
                    () => {
                        setTimeout(() => {
                            resetForm();
                        }, 1000);
                    }
                );
            }
            else{
                AlertService.presentAlert('Ungültige Anmeldung', 'Bitte Eingabe überprüfe und erneut versuchen.')
            }

        }

        function onInvalidSubmit({ values, errors, results })
        {
            console.log(values); // current form values
            console.log(errors); // a map of field names and their first error message
            console.log(results); // a detailed map of field names and their validation results

            AlertService.presentAlert('Fehler', 'Die Anmeldung ist fehlgeschlagen. Bitte Eingabe überprüfen und erneut versuchen.');
        }

        return { form, onSubmit, onInvalidSubmit, schema }
    }
});
