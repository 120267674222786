import {IDashboard} from '@/models/dashboard.model';
import ContentfulProvider from '@/provider/contentful.provider';

export class AssetService
{
    getAsset(id: string, query?: any): Promise<IDashboard|any>
    {
        return ContentfulProvider.getAsset(id, query).then((asset) => {

            console.log(`${asset.fields.file.url}?fit=pad`)

            // const items = res;
            //
            // const dashboard = items.find((item) => {
            //     return item.sys.contentType.sys.id === 'dashboard';
            // });

            // @ts-ignore
            // console.log('[DashboardService] dashboard', dashboard.fields)

            // return Dashboard.transferContentfulEntry(dashboard);
        });
    }

    buildMap(mapAsset, openModal: any)
    {
        const map: any = {areas: []};

        try {
            if (mapAsset && mapAsset.areas)
            {
                for (const area of mapAsset.areas)
                {
                    map.areas.push(
                        {
                            coordinates: area.coordinates,
                            shape: area.shape ? area.shape : 'rect',
                            onClick: () => {
                                if (area.modal)
                                {
                                    openModal(area.modal);
                                }
                            }
                        }
                    )
                }
            }

            return map;
        }
        catch (e){
            console.error(e)
        }
    }

}

export default new AssetService();
