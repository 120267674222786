
import {defineComponent, ref, toRefs} from 'vue';
import {IonRow, IonCol, IonItem, IonCheckbox, IonLabel, IonText} from '@ionic/vue';
import {Form} from 'vee-validate';
import PersonaFormInput from '@/components/form/form-input/FormInput.vue';
import PersonaFormTextarea from '@/components/form/form-textarea/FormTextarea.vue';
import PersonaFormCheckbox from '@/components/form/form-checkbox/FormCheckbox.vue';
import {FormTransfer, IForm} from '@/models/form.model';
import * as yup from 'yup';
import yupLocaleDe from '@/i18n/de.yupLocale';
import AlertService from '@/services/alert.service';
import BolProvider from '@/provider/bol.provider';
import UtilityService from '@/services/utility.service';
import AnalyticsService from '@/services/analytics.service';

yup.setLocale(yupLocaleDe);

export default defineComponent({
    name: 'FormProjectRequest',
    components: {
        PersonaFormInput,
        PersonaFormTextarea,
        PersonaFormCheckbox,
        Form,
        IonLabel,
        IonText,
        IonCheckbox,
        IonRow,
        IonCol,
        IonItem
    },
    props: {
        formTitle: {
            type: String,
            required: false
        },
        formType: {
            type: String,
            required: true
        },
        recipient: {
            type: Object,
            required: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const { formType, formTitle, recipient } = toRefs(props);

        const form = ref({});
        const schema = ref({});

        const setupForm = () => {
            // @ts-ignore
            schema.value = yup.object({
                customerName: yup.string().required().label('Kundenname'),
                customerPointOfContact: yup.string().required().label('Ansprechpartner Kunde'),
                text: yup.string().label('Freitext'),
                outsourcing: yup.boolean().label('Outsourcing'),
                interimMangerFreelancer: yup.boolean().label('Interim Manager / Freelancer'),
                recruitmentProcessOutsourcing: yup.boolean().label('Recruitment Process Outsourcing'),
                hrProjects: yup.boolean().label('HR-Projekte'),
                specialTopic: yup.boolean().label('Sonderthema'),
            });


            const actForm = form.value;

            // @ts-ignore
            actForm.title = formTitle.value;
            // @ts-ignore
            actForm.type = formType.value;
            // @ts-ignore
            actForm.inputs = [
                {label: 'Kundenname', name: 'customerName', type: 'text', required: true, value: '', placeholder: '', disabled: false},
                {label: 'Ansprechpartner Kunde', name: 'customerPointOfContact', type: 'text', required: true, value: '', placeholder: '', disabled: false}
            ];
            // @ts-ignore
            actForm.checkboxes = [
                { label: 'Outsourcing', name: 'outsourcing', value: false },
                { label: 'Interim Manager / Freelancer', name: 'interimMangerFreelancer', value: false },
                { label: '<b>R</b>ecruitment <b>P</b>rocess<b> O</b>utsourcing', name: 'recruitmentProcessOutsourcing', value: false },
                { label: 'HR-Projekte', name: 'hrProjects', value: false },
                { label: 'Sonderthema', name: 'specialTopic', value: false },
            ];

            const rows = UtilityService.zoomFactor() === 150 ? 9 : 12;
            // @ts-ignore
            actForm.textareas = [
                {name: 'text', label: '', required: false, value: '', placeholder: 'Freitext ...', rows: rows}
            ];
            // @ts-ignore
            actForm.recipient = recipient.value ? recipient.value : {};

            if (recipient.value)
            {
                // @ts-ignore
                actForm.inputs.push(
                    // {label: 'Empfänger', name: 'emailRecipient', type: 'email', required: false, value: 's.kroell@labor.digital', placeholder: '', disabled: true}
                    {label: 'Empfänger', name: 'emailRecipient', type: 'email', required: false, value: recipient.value.email, placeholder: '', disabled: true}
                );
            }
        }
        setupForm();

        function onSubmit(values: any, { resetForm })
        {
            // console.log('onSubmit values', values);

            // @ts-ignore
            const formTransfer = FormTransfer.create(form!.value, values);
            console.log('[FormTransfer] formTransfer', formTransfer);

            const request = BolProvider.sendForm(formTransfer);
            if (request)
            {
                request.then(() => {

                    AnalyticsService.trackFormEvent(formTransfer);

                    // @ts-ignore
                    AlertService.presentAlert('Versendet', 'Das Formular wurde erfolgreich an "' + form!.value.recipient.email + '" versendet.').then(() => {
                        resetForm();

                        // FIXME resetForm() does not reset checkboxes and validates the form so the required errors appear
                        // FIXME I could figure out, how to handle this. So the fast solution is to setup the form new.
                        form.value = {};
                        setTimeout(() => {
                            setupForm();
                        })
                    });
                });
            }
        }

        function onInvalidSubmit({ values, errors, results })
        {
            AnalyticsService.trackFormEvent({type: 'projektanfrage', title: 'Projektanfrage'}, 'error');

            AlertService.presentAlert('Nicht versendet', 'Das Formular konnte nicht versendet werden, bitte erneut versuchen.');
        }

        return { form, onSubmit, onInvalidSubmit, schema }
    }
});
