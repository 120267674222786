import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_asset = _resolveComponent("persona-asset")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "persona__image-grid" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, { class: "ion-justify-content-center ion-align-items-center" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.grid.images, (image, imageKey) => {
            return (_openBlock(), _createBlock(_component_ion_col, {
              class: "ion-margin-bottom ion-justify-content-center ion-align-items-center ion-text-center ion-align-self-center",
              size: "4",
              key: imageKey
            }, {
              default: _withCtx(() => [
                image
                  ? (_openBlock(), _createBlock(_component_persona_asset, {
                      key: 0,
                      asset: {url: image.url},
                      onClick: ($event: any) => (_ctx.openModal(image))
                    }, null, 8, ["asset", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}