
import {defineComponent} from 'vue';
import PersonaDocument from '@/components/document/Document.vue';
import PersonaParagraph from '@/components/paragraph/Paragraph.vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import {List, ListItem} from '@/models/list.model';
import {IonText} from '@ionic/vue';
import { Browser } from '@capacitor/browser';

export default defineComponent ({
    name: 'List',
    components: {
        IonText,
        PersonaIcon,
        PersonaDocument,
        PersonaParagraph
    },
    props: {
        list: {
            type: List,
            required: true
        }
    },
    setup() {

        const onClick = (item: ListItem) => {
            console.log('item', item.ref)
            if (item.ref && item.ref.url)
            {
                Browser.open({url: 'https:' + item.ref.url});
            }
        }

        return { onClick};
    }
});
