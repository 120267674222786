
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: ''
        }
    },
    methods: {
        iconPath() {
            return `/assets/content/icons/${this.name}.svg`;
        }
    }
});
