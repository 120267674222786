import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "persona__button" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_icon = _resolveComponent("persona-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.button)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 0,
          color: _ctx.button.color,
          size: _ctx.button.size,
          shape: _ctx.button.shape,
          onClick: _ctx.onClick,
          class: {'icon-only': _ctx.button.icon && !_ctx.button.text}
        }, {
          default: _withCtx(() => [
            (_ctx.button.icon)
              ? (_openBlock(), _createBlock(_component_persona_icon, {
                  key: 0,
                  slot: "icon-only",
                  name: _ctx.button.icon,
                  size: _ctx.button.text ? 'sm' : 'xlg',
                  class: {'mr-1': _ctx.button.text}
                }, null, 8, ["name", "size", "class"]))
              : _createCommentVNode("", true),
            (_ctx.button.text)
              ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.button.text), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["color", "size", "shape", "onClick", "class"]))
      : _createCommentVNode("", true)
  ]))
}