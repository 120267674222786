
import {defineComponent, toRefs} from 'vue';
import {IonRow, IonCol, IonItem, IonCheckbox, IonLabel, IonText} from '@ionic/vue';
import {Form} from 'vee-validate';
import PersonaFormInput from '@/components/form/form-input/FormInput.vue';
import PersonaFormTextarea from '@/components/form/form-textarea/FormTextarea.vue';
import PersonaFormCheckbox from '@/components/form/form-checkbox/FormCheckbox.vue';
import {FormTransfer, IForm} from '@/models/form.model';

import * as yup from 'yup';
import yupLocaleDe from '@/i18n/de.yupLocale';
import AlertService from '@/services/alert.service';
import BolProvider from '@/provider/bol.provider';
import StorageService from '@/services/storage.service';
import {STORAGE_KEYS} from '@/models/app.model';
import AnalyticsService from '@/services/analytics.service';

yup.setLocale(yupLocaleDe);

export default defineComponent({
    name: 'FormBasic',
    components: {
        PersonaFormInput,
        PersonaFormTextarea,
        PersonaFormCheckbox,
        Form,
        IonLabel,
        IonText,
        IonCheckbox,
        IonRow,
        IonCol,
        IonItem
    },
    props: {
        formTitle: {
            type: String,
            required: false
        },
        formType: {
            type: String,
            required: true
        },
        recipient: {
            type: Object,
            required: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const { formType, formTitle, recipient } = toRefs(props);

        const schema = yup.object({
            customerName: yup.string().required().label('Kundenname'),
            customerPointOfContact: yup.string().required().label('Ansprechpartner Kunde'),
            text: yup.string().label('Freitext')
        });

        const form: IForm = {};
        form.title = formTitle.value;
        form.type = formType.value;
        form.inputs = [
            {label: 'Kundenname', name: 'customerName', type: 'text', required: true, value: '', placeholder: '', disabled: false},
            {label: 'Ansprechpartner Kunde', name: 'customerPointOfContact', type: 'text', required: true, value: '', placeholder: '', disabled: false}
        ];

        if (recipient.value)
        {
            form.inputs.push(
                {label: 'Empfänger', name: 'emailRecipient', type: 'email', required: false, value: recipient.value.email, placeholder: '', disabled: true}
            );
        }

        form.textareas = [
            {name: 'text', label: '', required: false, value: '', placeholder: 'Freitext ...', rows: 12}
        ];
        form.recipient = recipient.value ? recipient.value : {};

        function onSubmit(values: any, { resetForm })
        {
            // console.log('onSubmit values', values);

            // form.recipient.email = 's.kroell@labor.digital';

            const formTransfer = FormTransfer.create(form, values);
            console.log('[FormTransfer] formTransfer', formTransfer);

            const request = BolProvider.sendForm(formTransfer);
            if (request)
            {
                request.then(() => {

                    AnalyticsService.trackFormEvent(formTransfer);

                    // console.log('[FormIsAnalyse] form send');
                    AlertService.presentAlert('Versendet', 'Das Formular wurde erfolgreich an "' + form.recipient.email + '" versendet.').then(() => {
                        resetForm();
                    });
                });
            }
        }

        function onInvalidSubmit({ values, errors, results })
        {
            AnalyticsService.trackFormEvent({type: 'kontakt', title: 'Kontakt'}, 'error');

            AlertService.presentAlert('Nicht versendet', 'Das Formular konnte nicht versendet werden, bitte erneut versuchen.');
        }

        return { form, onSubmit, onInvalidSubmit, schema }
    }
});
