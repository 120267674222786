export interface IMenuItem{
    label: string;
    path?: string;
    items?: Array<IMenuItem>;
    openModal?: boolean;
    page?: any;
}

export class MenuItem implements IMenuItem
{
    label!: string;
    path?: string;
    items?: Array<IMenuItem>;
    openModal?: boolean;
    page?: any;

    constructor(data?: any)
    {
        if (data)
        {
            this.label = data.label;
            this.path = data.path;
            this.openModal = data.openModal;
            this.page = data.page;

            this.items = [];
            if (data.items)
            {
                data.items.forEach((item) => {
                    // @ts-ignore
                    this.items.push(new MenuItem(item));
                })
            }
        }
    }
}

export interface IMenu {
    title: string;
    items: IMenuItem[];
}

export class Menu implements IMenu
{
    title!: string;
    items!: IMenuItem[];
    itemsApp!: IMenuItem[];

    constructor(data?: any)
    {
        if (data)
        {
            this.title = data.title;

            this.items = [];
            if (data.items)
            {
                data.items.forEach((item) => {
                    // @ts-ignore
                    this.items.push(new MenuItem(item));
                })
            }
        }
    }
}
