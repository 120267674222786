
import { defineComponent} from 'vue';
import { IonCard, IonCardContent, IonRow, IonCol } from '@ionic/vue';
// import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue';

export default defineComponent({
    name: 'Error',
    components: {
        IonCard,
        IonCardContent,
        IonRow,
        IonCol
    },
    props: {
        message: {
            type: String,
            required: true
        }
    }
})
