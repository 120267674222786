import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "persona__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_persona_paragraph = _resolveComponent("persona-paragraph")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_persona_icon = _resolveComponent("persona-icon")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.list.insideCard)
      ? (_openBlock(), _createBlock(_component_ion_card, {
          key: 0,
          class: "ion-no-margin"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, {
              inset: true,
              lines: 'full'
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list.items, (item, itemKey) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: itemKey,
                    onClick: ($event: any) => (_ctx.onClick(item)),
                    class: "ion-align-items-start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, {
                            color: item.ref ? 'primary' : 'text'
                          }, {
                            default: _withCtx(() => [
                              _createVNode("h1", null, [
                                _createVNode("b", null, _toDisplayString(item.title), 1)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["color"]),
                          (item.documents)
                            ? (_openBlock(), _createBlock(_component_ion_text, {
                                key: 0,
                                color: "text"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(item.documents, (document, documentKey) => {
                                    return (_openBlock(), _createBlock("div", {
                                      class: ["full-width", {'ion-margin-top': documentKey === 0}],
                                      key: documentKey
                                    }, [
                                      _createVNode("p", null, [
                                        (document.paragraphs && document.paragraphs.length)
                                          ? (_openBlock(), _createBlock(_component_persona_paragraph, {
                                              key: 0,
                                              class: "ion-margin-bottom",
                                              paragraphs: document.paragraphs
                                            }, null, 8, ["paragraphs"]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ], 2))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      (item.ref)
                        ? (_openBlock(), _createBlock(_component_ion_avatar, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_persona_icon, {
                                name: "plus",
                                size: "sm"
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ion_list, {
          key: 1,
          inset: true,
          lines: 'full',
          class: "persona__list--single"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list.items, (item, itemKey) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: itemKey,
                onClick: ($event: any) => (_ctx.onClick(item)),
                class: "ion-align-items-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, {
                        color: item.ref ? 'primary' : 'text'
                      }, {
                        default: _withCtx(() => [
                          _createVNode("h1", null, [
                            _createVNode("b", null, _toDisplayString(item.title), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["color"]),
                      (item.documents)
                        ? (_openBlock(), _createBlock(_component_ion_text, {
                            key: 0,
                            color: "text"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createBlock(_Fragment, null, _renderList(item.documents, (document, documentKey) => {
                                return (_openBlock(), _createBlock("div", {
                                  class: ["full-width", {'ion-margin-top': documentKey === 0}],
                                  key: documentKey
                                }, [
                                  _createVNode("p", null, [
                                    (document.paragraphs && document.paragraphs.length)
                                      ? (_openBlock(), _createBlock(_component_persona_paragraph, {
                                          key: 0,
                                          class: "ion-margin-bottom",
                                          paragraphs: document.paragraphs
                                        }, null, 8, ["paragraphs"]))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024),
                  (item.ref)
                    ? (_openBlock(), _createBlock(_component_ion_avatar, {
                        key: 0,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_persona_icon, {
                            name: "plus",
                            size: "sm"
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }))
  ]))
}