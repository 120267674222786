
import {defineComponent, toRefs} from 'vue';
import PersonaError from '@/components/error/Error.vue';
import PersonaHeader from '@/components/header/Header.vue';
import PersonaFormProjectRequest from '@/components/form/form-project-request/FormProjectRequest.vue';
import PersonaFormIstAnalyse from '@/components/form/form-ist-analyse/FormIstAnalyse.vue';
import PersonaFormBasic from '@/components/form/form-basic/FormBasic.vue';
import PersonaFormLogin from '@/components/form/form-login/FormLogin.vue';
import {IonRow, IonCol} from '@ionic/vue';
import {IForm} from '@/models/form.model';

export default defineComponent({
    name: 'Form',
    components: {
        PersonaError,
        PersonaHeader,
        PersonaFormIstAnalyse,
        PersonaFormBasic,
        PersonaFormLogin,
        PersonaFormProjectRequest,
        IonRow,
        IonCol,
    },
    props: {
        formType: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        image: {
            type: Object,
            required: false
        },
        recipient: {
            type: Object,
            required: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        },
    },
    setup(props){

        const { formType, title } = toRefs(props);

        const form: IForm = {
            type: formType.value,
            title: title.value,
            subTitle: ''
        }

        function getFormType()
        {
            return form.type ? form.type.toLowerCase() : '';
        }

        const type = getFormType();
        switch (type)
        {
            case 'projectrequest':
            case 'projektanfrage':
                if (!form.title){
                    form.title = 'Projektanfrage';
                }
                form.type = 'Projektanfrage';
                break;
            case 'ist-analyse':
                if (!form.title){
                    form.title = 'IST-Analyse';
                }
                form.type = 'IST-Analyse';
                break;
            case 'kontakt':
                if (!form.title){
                    form.title = 'Kontakt';
                }
                form.type = 'Kontakt';
                break;
        }

        return { form, getFormType };
    }
});
