// import {IMenu, Menu, MenuItem} from '@/models/menu.model';
import TopicService from '@/services/topic.service';
import StorageService from '@/services/storage.service';
import UtilityService from '@/services/utility.service';
import {STORAGE_KEYS} from '@/models/app.model';
import {IMenu, Menu, MenuItem} from '@/models/menu.model';

export class MenuService
{
    async reload(clearCache: boolean = true)
    {
        if (clearCache)
        {
            await StorageService.clear();
        }
        window.location.reload();
    }

    async getMainMenu()
    {
        const dashboard = await StorageService.get(STORAGE_KEYS.DASHBOARD);
        // console.log('[MenuService] getMainMenu dashboard', dashboard);

        const menu = new Menu({
            title: 'Inhaltsübersicht'
        });

        if (dashboard && dashboard.topics)
        {
            for (const topic of dashboard.topics) {

                const menuItem = new MenuItem({
                    label: topic.title,
                    path: topic.path
                });

                menuItem.label = await UtilityService.hyphen(topic.title);

                if(topic.pages)
                {
                    const items: Array<MenuItem> = [];
                    for (const page of topic.pages)
                    {
                        if (!page.isForm && page.layout !== 'cover')
                        {
                            const title = await UtilityService.hyphen(page.title);
                            items.push({
                                label: title,
                                path: page.path
                            });
                        }
                    }
                    menuItem.items = items;
                }

                menu.items.push(menuItem);
            }
        }

        const menuItem = new MenuItem({
            label: 'App',
            path: 'app',
            items: [
                {
                    label: 'Aktualisieren',
                    path: 'update'
                },
                {
                    label: 'Abmelden',
                    path: 'login'
                }
            ]
        });
        menu.itemsApp = [menuItem];

        return menu;
    }

    async getSecondaryMenu()
    {
        const app = await StorageService.get(STORAGE_KEYS.APP);
        // console.log('[MenuService] getMainMenu dashboard', dashboard);

        const menu = new Menu({
            title: 'IST-Analysen'
        });

        if (app && app.analyses)
        {
            for (const analyse of app.analyses) {

                const menuItem = new MenuItem({
                    label: analyse.title ? analyse.title.replace('IST-Analyse ', '') : '',
                    path: analyse.path,
                    openModal: true,
                    page: analyse
                });

                menuItem.label = await UtilityService.hyphen(menuItem.label);

                menu.items.push(menuItem);
            }
        }

        return menu;
    }



    getMockedMainMenu(): IMenu
    {
        const branches = TopicService.getTopicByPath('/branchenkompetenz');
        const portfolio = TopicService.getTopicByPath('/portfolio');
        const persona = TopicService.getTopicByPath('/warum-mit-persona-service');
        const company = TopicService.getTopicByPath('/unternehmensverbund');
        const system = TopicService.getTopicByPath('/bewerbermanagementsystem');
        const project = TopicService.getTopicByPath('/projektanfrage');

        return {
            title: 'Inhaltsübersicht Test',
            items: [
                {
                    label: 'Branchenkompetenz',
                    path: '/branchenkompetenz',
                    items: [
                        {
                            label: 'Produzierendes Gewerbe',
                            path: ''
                        },
                        {
                            label: 'Handel',
                            path: ''
                        },
                        {
                            label: 'Logistik',
                            path: ''
                        },
                        {
                            label: 'Dienstleistungen, Versicherung & Banken',
                            path: ''
                        },
                        {
                            label: 'Öffentliche Einrichtungen',
                            path: ''
                        },
                        {
                            label: 'Gesundheit – Erziehung – Pflege',
                            path: ''
                        }
                    ]
                },
                // {
                //     // @ts-ignore
                //     label: portfolio.title,
                //     path: '/portfolio',
                //     items: [
                //         {
                //             label: 'Vor Ort für Sie',
                //             path: ''
                //         },
                //         {
                //             label: 'Stark aufgestellt',
                //             path: ''
                //         },
                //         {
                //             label: 'Strategische Zusammenarbeit',
                //             path: ''
                //         }
                //     ]
                // },
                // {
                //     // @ts-ignore
                //     label: persona.title,
                //     path: '/warum-mit-persona-service',
                //     items: [
                //         {
                //             label: 'Warum überhaupt Zeitarbeit?',
                //             path: ''
                //         },
                //         {
                //             label: 'Warum mit uns?',
                //             path: ''
                //         }
                //     ]
                // },
                // {
                //     // @ts-ignore
                //     label: company.title,
                //     path: '/unternehmensverbund',
                //     items: []
                // },
                // {
                //     // @ts-ignore
                //     label: system.title,
                //     path: '/bewerbermanagementsystem',
                //     items: []
                // },
                // {
                //     // @ts-ignore
                //     label: project.title,
                //     path: '/projektanfrage',
                //     items: []
                // }
            ]
        }
    }
}

export default new MenuService();
