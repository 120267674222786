
import {defineComponent, toRefs} from 'vue';
import PersonaBaseLayout from '@/components/layout/base/BaseLayout.vue';
import PersonaForm from '@/components/form/Form.vue';
import {Topic} from '@/models/topic.model';
import {Page} from '@/models/page.model';
import SessionService from '@/services/session.service';

export default defineComponent({
    name: 'FormPage',
    components: {
        PersonaBaseLayout,
        // PersonaHeader,
        PersonaForm
    },
    props: {
        topic: {
            type: Topic,
            required: false
        },
        page: {
            type: Page,
            required: true
        },
        isModal: {
            type: Boolean,
            default: false
        },
    },
    setup(props)
    {
        const { page } = toRefs(props);

        const recipient: any = {};

        // can be used for testing
        if (process.env.VUE_APP_SEND_FORMS_TO_LOGGED_IN_USER == 'true')
        {
            const account = SessionService.getCurrentUser();
            if (account)
            {
                recipient.email = account.userName;
                recipient.name = account.name;
            }
        }
        else{
            if (page.value.recipientEmail)
            {
                recipient.email = page.value.recipientEmail;
            }
            if (page.value.recipientName)
            {
                recipient.name = page.value.recipientName;
            }
        }

        // console.log('[FormPage] page', page.value)
        // console.log('[FormPage] recipient', recipient)

        return { recipient }
    },
    data() {
        return {
            currentPath: this.$route.path,
        };
    }
});
