
import {defineComponent, toRefs} from 'vue';
import {IonItem, IonInput, IonLabel, IonText} from '@ionic/vue';
import { Field, ErrorMessage } from 'vee-validate';

export default defineComponent({
    name: 'FormTextarea',
    components: {
        Field,
        ErrorMessage,
        IonItem,
        IonLabel,
        IonInput,
        IonText
    },
    props: {
        required: {
            type: Boolean,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        rows: {
            type: Number,
            default: 12,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        maxLength: {
            type: Number,
            required: false
        }
    },
    setup(props, { emit })
    {
        const { maxLength } = toRefs(props);

        const length = maxLength && maxLength.value ? maxLength.value : 5;
        function getLengthColor(text?: string)
        {
            if (text && maxLength && text.length > length)
            {
                return 'danger';
            }
            return 'text';
        }

        function textChanged(text)
        {
            // console.log('textChanged', text);
            emit('onTextChanged', text)
        }

        return {getLengthColor, textChanged}
    }
});
