import BolProvider from '@/provider/bol.provider';
import {Dashboard} from '@/models/dashboard.model';
import {App, STORAGE_KEYS} from '@/models/app.model';
import StorageService from '@/services/storage.service';
import ContentfulProvider from '@/provider/contentful.provider';
import useEmitter from '@/composables/useEmitter';

export class ContentfulService
{
    getData(): Promise<any>
    {
        let promise = BolProvider.getContentfulEntries();
        if (window.origin === 'http://192.168.0.187:4200')
        {
            promise = ContentfulProvider.getEntries();
        }

        return promise.then(async (res) => {
            // console.log('[ContentfulService] res', res);
            if (res && res.items)
            {
                const items = res.items;

                const dashboardItem = items.find((item) => {
                    return item.sys.contentType.sys.id === 'dashboard';
                });

                const dashboard = Dashboard.transferContentfulEntry(dashboardItem);
                if (dashboard)
                {
                    await StorageService.set(STORAGE_KEYS.DASHBOARD, dashboard);
                }

                const appItem = items.find((item) => {
                    return item.sys.contentType.sys.id === 'app';
                });
                const app = App.transferContentfulEntry(appItem);
                if (app)
                {
                    await StorageService.set(STORAGE_KEYS.APP, app);
                }

                return {
                    app,
                    dashboard
                }
            }
            else {
                return this.getStoredData();
            }
        }).catch(async (err) => {
            console.log('[ContentfulService::getData] err', err);

            return this.getStoredData();
        });
    }

    protected async getStoredData()
    {
        const app = await StorageService.get(STORAGE_KEYS.APP);
        const dashboard = await StorageService.get(STORAGE_KEYS.DASHBOARD);

        // console.log('[ContentfulService::getStoredData] data', {app, dashboard});

        return {
            app,
            dashboard
        }
    }
}

export default new ContentfulService();
