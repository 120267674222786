
import {defineComponent} from 'vue';
import PersonaHeader from '@/components/header/Header.vue';
import PersonaParagraph from '@/components/paragraph/Paragraph.vue';
import {IonRow, IonCol, IonText} from '@ionic/vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import PersonaImageGallery from '@/components/image-gallery/ImageGallery.vue';
import PersonaImageGrid from '@/components/image-grid/ImageGrid.vue';
import PersonaGrid from '@/components/grid/Grid.vue';
import PersonaCard from '@/components/card/Card.vue';
import PersonaButton from '@/components/button/Button.vue';
import PersonaList from '@/components/list/List.vue';
import VSwitch from '@lmiller1990/v-switch';

export default defineComponent({
    name: 'Document',
    components: {
        PersonaHeader,
        PersonaAsset,
        PersonaParagraph,
        PersonaImageGallery,
        PersonaImageGrid,
        PersonaCard,
        PersonaGrid,
        PersonaButton,
        PersonaList,
        VSwitch,
        IonRow,
        IonText,
        IonCol
    },
    props: {
        documents: {
            type: Object,
            required: true
        }
    },
    setup(props)
    {


        return {  };
    }
});
