import {IPage, Page} from '@/models/page.model';
import {Asset, IAsset} from '@/models/asset.model';
import {Entry} from 'contentful';
import {IContentfulEntity} from '@/models/contentfulEntity.model';
import UtilityService from '@/services/utility.service';

export interface ITopic 
{
    id: string;
    // img?: string;
    title: string;
    titleHyphen?: string;
    path: string;
    subTitle: string;
    cover?: IAsset;
    pages?: Array<IPage>;
}

export class Topic implements ITopic, IContentfulEntity
{
    id!: string;
    // img?: string;
    title!: string;
    titleHyphen!: string;
    path!: string;
    subTitle!: string;
    cover?: IAsset;
    pages?: Array<IPage>;

    static transferContentfulEntry(entry?: Entry<any>): Topic|any
    {
        const topic = new Topic();

        if (entry!.fields)
        {
            topic.id = entry!.sys.id;
            topic.title = entry!.fields.titel;
            topic.subTitle = entry!.fields.subtitel;
            topic.path = entry!.fields.slug;

            if (topic!.title)
            {
                UtilityService.hyphen(topic.title).then(r => topic.titleHyphen = r);
            }

            if (entry!.fields.titelbild)
            {
                topic.cover = Asset.transferContentfulEntry(entry!.fields.titelbild);
            }

            if (entry!.fields.unterseite)
            {
                // console.log('entry!.fields.unterseite', topic.title, entry!.fields.unterseite)
                const pages: Array<Page> = [];
                entry!.fields.unterseite.forEach((page) => {
                    const actPage = Page.transferContentfulEntry(page);
                    if (actPage)
                    {
                        pages.push(actPage);
                    }
                });
                topic.pages = pages;
            }

            return topic;
        }
    }
}


