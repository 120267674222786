import {Topic} from '@/models/topic.model';
import {IPage, Page} from '@/models/page.model';
import {RouteLocationNormalized} from 'vue-router';
import EtrackerService from '@/services/etracker.service';
import {event, PageView, ScreenView, pageview, screenview} from 'vue-gtag';

export class AnalyticsService
{
    trackLoginEvent()
    {
        this.trackEvent('login', { method: 'Microsoft' }, 'Authentifizierung', 'Login');
    }

    trackLogoutEvent()
    {
        this.trackEvent('logout', { method: 'Microsoft' }, 'Authentifizierung', 'Logout');
    }

    trackFormEvent(formTransfer: any, action: string = 'send')
    {
        try{
            const params = formTransfer.recipientMail ? {
                recipientMail: formTransfer.recipientMail,
                recipientName: formTransfer.recipientName,
            } : {};

            this.trackEvent(formTransfer.type + '_' + action, params, 'Formulare', formTransfer.title);
        }
        catch (e){
            console.error(e);
        }
    }

    trackEvent(action: string, params?: any, category: string = 'App', label?: string)
    {
        if (params)
        {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params.event_category = category;
            // eslint-disable-next-line @typescript-eslint/camelcase
            params.event_label = label;
        }
        else{
            params = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_category: category,
                // eslint-disable-next-line @typescript-eslint/camelcase
                event_label: label
            }
        }

        if (process.env.VUE_APP_CONTEXT !== 'development')
        {
            // console.log('[AnalyticsService::trackEvent]', action, category, label);
            event(action, params);
        }
    }

    trackRouteChange(route: RouteLocationNormalized)
    {
        this.trackPageview(route.name, route.path, route.params.id)
    }

    trackPageChange(page: Page, topic?: Topic)
    {
        let pageTitle = page.tabTitle ? page.tabTitle : page.title;

        if (!pageTitle && page.isForm && page.formType)
        {
            pageTitle = page.formType;
        }

        if (pageTitle)
        {
            if (topic){
                this.trackPageview(pageTitle, '/topics/' + topic.path + '/' + page.path);
            }
            else{
                let path = page.path ? page.path : pageTitle.toLowerCase();
                if (page.isForm)
                {
                    path = '/forms/' + path;
                }

                this.trackPageview(pageTitle, path);
            }
        }
    }

    trackTabChange(topic: Topic, tabIndex: number)
    {
        if (topic.pages && topic.pages.length)
        {
            try {
                const currentPage: IPage = topic.pages[tabIndex];
                if (currentPage)
                {
                    this.trackPageChange(currentPage, topic);
                }
            }
            catch (e){
                console.error(e);
            }
        }
    }

    protected trackPageview(pageTitle: string|any, path?: string, id?: string|any)
    {
        pageTitle = pageTitle.replace('Page', '');
        if (pageTitle === 'Tabs' && id)
        {
            pageTitle = id.charAt(0).toUpperCase() + id.slice(1);
        }

        if (path)
        {
            path = path.replace(/\s+/g, '-');
            if (path.charAt(0) !== '/')
            {
                path = '/' + path;
            }
        }

        const pageView: PageView = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            page_title: pageTitle,
            // eslint-disable-next-line @typescript-eslint/camelcase
            page_path: path
        }

        if (process.env.VUE_APP_CONTEXT !== 'development')
        {
            // console.log('[AnalyticsService::trackPageview]', pageTitle, path);
            pageview(pageView);

            EtrackerService.trackPageChange(pageTitle);
        }

    }
}
export default new AnalyticsService();
