import { getCurrentInstance } from 'vue';
import EventService from '@/services/event.service';

export default function useEmitter()
{
    return EventService.emitter;

    // const internalInstance = getCurrentInstance();
    // console.log('internalInstance', internalInstance)
    // return internalInstance ? internalInstance!.appContext.config.globalProperties.emitter : {};
}
