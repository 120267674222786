import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "persona__content"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "ion-margin-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_header = _resolveComponent("persona-header")!
  const _component_persona_grid = _resolveComponent("persona-grid")!
  const _component_persona_document = _resolveComponent("persona-document")!
  const _component_persona_image_gallery = _resolveComponent("persona-image-gallery")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_persona_image_grid = _resolveComponent("persona-image-grid")!
  const _component_persona_map_asset = _resolveComponent("persona-map-asset")!

  return (_ctx.page)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_ctx.page && _ctx.displayHeader)
          ? (_openBlock(), _createBlock(_component_persona_header, {
              key: 0,
              title: _ctx.page.titleHyphen ? _ctx.page.titleHyphen : _ctx.page.title,
              "title-color": 'text-color',
              displayLogo: false
            }, null, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.page.grid)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode(_component_persona_grid, {
                grid: _ctx.page.grid
              }, null, 8, ["grid"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.page.documents && _ctx.page.documents.length)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode(_component_persona_document, {
                documents: _ctx.page.documents
              }, null, 8, ["documents"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.page.imageGallery)
          ? (_openBlock(), _createBlock(_component_ion_grid, {
              key: 3,
              class: "ion-margin-top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_persona_image_gallery, {
                  gallery: _ctx.page.imageGallery
                }, null, 8, ["gallery"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.page.imageGrid)
          ? (_openBlock(), _createBlock(_component_ion_grid, {
              key: 4,
              class: "ion-margin-top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_persona_image_grid, {
                  grid: _ctx.page.imageGrid
                }, null, 8, ["grid"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.page.mapAsset && (_ctx.page.mapAsset.image || _ctx.page.mapAsset.hasAreaImages))
          ? (_openBlock(), _createBlock("div", {
              key: 5,
              class: ["persona__content-image-maps", {'ion-margin-top': _ctx.page.mapAsset.hasMarginTop}]
            }, [
              _createVNode(_component_persona_map_asset, {
                "map-asset": _ctx.page.mapAsset
              }, null, 8, ["map-asset"])
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}