import { hyphenate } from "hyphen/de";
import {getCurrentInstance} from 'vue';

export class UtilityService
{
    async hyphenAsync(label)
    {
        return await hyphenate(label);
    }

    hyphen(label)
    {
        return hyphenate(label);
    }

    isJsonString(str)
    {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    zoomFactor()
    {
        return Math.round(window.devicePixelRatio * 100);
    }

    forceUpdate()
    {
        const instance = getCurrentInstance();
        if (instance)
        {
            // @ts-ignore
            instance.proxy.$forceUpdate();
        }
    }
}

export default new UtilityService();
