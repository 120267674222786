import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "persona__brand" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_asset = _resolveComponent("persona-asset")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.app && _ctx.app.logo)
      ? (_openBlock(), _createBlock(_component_persona_asset, {
          key: 0,
          asset: _ctx.app.logo,
          onClick: _ctx.reload
        }, null, 8, ["asset", "onClick"]))
      : (_openBlock(), _createBlock("img", {
          key: 1,
          src: "/assets/content/persona-logo.svg",
          alt: "persona services",
          width: "400",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
        }))
  ]))
}