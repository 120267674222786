
import {defineComponent, toRefs} from 'vue';
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController, IonRow } from '@ionic/vue';
import {Page} from '@/models/page.model';
// import PersonaContentPage from '@/components/pages/content-page/ContentPage.vue';
import PersonaFormPage from '@/components/pages/form-page/FormPage.vue';
import PersonaContent from '@/components/content/Content.vue';
import PersonaHeader from '@/components/header/Header.vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import AssetService from '@/services/asset.service';
import PersonaGrid from '@/components/grid/Grid.vue';

import AnalyticsService from '@/services/analytics.service';

export default defineComponent({
    name: 'ModalPage',
    components: {
        IonRow,
        PersonaContent,
        PersonaFormPage,
        PersonaHeader,
        PersonaAsset,
        PersonaIcon,
        PersonaGrid,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar
    },
    props: {
        page: {
            type: Page,
            required: true
        },
    },
    setup(props)
    {
        const { page } = toRefs(props);
        // console.log('[ModalPage] page', page.value)

        AnalyticsService.trackPageChange(page.value)

        function closeModal()
        {
            modalController.dismiss();
        }

        async function openModal(modalPage?: Page)
        {
            console.log('[ModalPage] openModal', modalPage)

            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: modalPage
                },
            })
            return modal.present();
        }

        const map = page.value ? AssetService.buildMap(page.value.mapAsset, openModal) : null;

        return { closeModal, map, openModal };
    }
});
