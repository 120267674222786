
import {defineComponent, ref, toRefs} from 'vue';
import {IonLabel, IonText, menuController, modalController} from '@ionic/vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import {IMenuItem} from '@/models/menu.model';
import MenuService from '@/services/menu.service';
import SessionService from '@/services/session.service';
import {EVENTS} from '@/models/app.model';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import AnalyticsService from '@/services/analytics.service';

export default defineComponent({
    name: 'MainMenu',
    components: {
        IonLabel,
        IonText,
        PersonaIcon
    },
    props: {
        type: {
            type: String,
            default: 'overlay'
        },
        menuId: {
            type: String,
            default: 'main'
        },
        side: {
            type: String,
            default: 'end'
        }
    },
    async setup(props)
    {
        const { menuId } = toRefs(props);

        const menu = ref({});
        const context = ref(process.env.VUE_APP_CONTEXT ? process.env.VUE_APP_CONTEXT : 'unknown');

        switch (menuId.value)
        {
            case 'secondary':
                menu.value = await MenuService.getSecondaryMenu();
                break;
            case 'main':
            default:
                menu.value = await MenuService.getMainMenu();
                break;
        }

        return { menu, context }
    },
    methods: {
        async openModal(page){
            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: page
                },
            });

            return modal.present();
        },
        openRoute(item: IMenuItem, parent?: IMenuItem)
        {
            menuController.close(this.menuId);

            // console.log('open Route ', item)

            if (item.path)
            {
                switch (item.path)
                {
                    case 'login':
                        SessionService.logout();
                        this.$router.replace({path: item.path});
                        break;

                    case 'update':
                        MenuService.reload();
                        break;

                    default:
                        if (parent){
                            this.$router.push({path: `/topics/${parent.path}`, query: {tab: item.path}});
                        }
                        else {
                            this.$router.push({path: `/topics/${item.path}`});
                        }
                }
            }
            else{
                if (item.openModal && item.page)
                {
                    this.openModal(item.page);
                }
            }

        },
        ionWillClose()
        {
            let label;

            switch (this.menuId)
            {
                case 'secondary':
                    this.$emit(EVENTS.SECONDARY_MENU_WILL_CLOSE, true);
                    label = 'Formularmenü geschlossen';
                    break;
                case 'main':
                default:
                    // console.log('[MainMenu] ionWillClose', this.menuId);
                    this.$emit(EVENTS.MAIN_MENU_WILL_CLOSE, true);
                    label = 'Hauptmenü geschlossen';
                    break;
            }

            AnalyticsService.trackEvent(this.menuId + '_menu_closed', {id: this.menuId}, 'App', label);
        },
        ionWillOpen()
        {
            // console.log('[MainMenu] ionWillOpen', this.menuId);

            let label;

            switch (this.menuId)
            {
                case 'secondary':
                    this.$emit(EVENTS.SECONDARY_MENU_WILL_OPEN, true);
                    MenuService.getSecondaryMenu().then((menu) => {
                        // console.log('[MainMenu] secondary ionWillOpen', menu);
                        this.menu = menu;
                    });
                    label = 'Formularmenü geöffnet';
                    break;
                case 'main':
                default:
                    this.$emit(EVENTS.MAIN_MENU_WILL_OPEN, true);
                    // update menu items when menu gets opened
                    MenuService.getMainMenu().then((menu) => {
                        // console.log('[MainMenu] ionWillOpen', menu);
                        this.menu = menu;
                    });
                    label = 'Hauptmenü geöffnet';
                    break;
            }

            AnalyticsService.trackEvent(this.menuId + '_menu_opened', {id: this.menuId}, 'App', label);
        }
    }
});
