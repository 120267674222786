
import {IonCol, IonRow, IonText, IonSlides, IonSlide} from '@ionic/vue';
import {defineComponent, ref, toRefs} from 'vue';
import PersonaBrand from '@/components/brand/Brand.vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { EffectFade } from 'swiper';
import { Pagination, Scrollbar } from 'swiper';

SwiperCore.use([EffectFade, Pagination, Scrollbar]);

import 'swiper/swiper-bundle.min.css';
// import 'swiper/css';

export default defineComponent({
    name: 'ImageGallery',
    components: {
        IonCol,
        IonRow,
        IonText,
        IonSlides,
        IonSlide,
        Swiper,
        SwiperSlide,
        PersonaAsset,
        PersonaBrand
    },
    props: {
        gallery: {
            type: Object,
            required: true
        }
    },
    setup(props)
    {
        const { gallery } = toRefs(props);

        // console.log('gallery', gallery.value)

        const slides = ref();
        const setSwiperInstance = (swiper: any) => {
            slides.value = swiper;
        }

        const onSlideChange = () => {
            // console.log('slide change');
        };

        const nextSlide = () => {
            slides.value.slideNext();
        };

        let pagination: any = false;
        let scrollbar: any = false;

        const actGallery = gallery.value;
        if (actGallery.pagination)
        {
            switch (actGallery.paginationType)
            {
                case 'bullet':
                case 'bullets':
                    pagination = { clickable: true, type: 'bullets' };
                    scrollbar = false;
                    break;
                case 'scrollbar':
                    pagination = false;
                    scrollbar = {
                        clickable: true,
                        hide: false
                    }
                    break;
            }
        }


        return { setSwiperInstance, nextSlide, onSlideChange, scrollbar, pagination, modules: [Pagination, Scrollbar] }
    }
});
