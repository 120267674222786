
import {IonCol, IonRow, IonText, modalController} from '@ionic/vue';
import { defineComponent } from 'vue';
import PersonaBrand from '@/components/brand/Brand.vue';
import PersonaIcon from '@/components/icon/Icon.vue';

export default defineComponent({
    name: 'Header',
    components: {
        IonCol,
        IonRow,
        IonText,
        PersonaIcon,
        PersonaBrand
    },
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String
        },
        forceSubTitle: {
            type: Boolean,
            default: false
        },
        displayLogo: {
            type: Boolean,
            default: true
        },
        displayCloseBtn: {
            type: Boolean,
            default: false
        },
        withPadding: {
            type: Boolean,
            default: false
        },
        titleColor: {
            type: String,
            default: 'text-color-secondary'
        },
        subTitleColor: {
            type: String,
            default: 'text-color'
        }
    },
    setup() {
        function closeModal()
        {
            modalController.dismiss();
        }

        return { closeModal }
    }
});
