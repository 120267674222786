import { Storage } from '@capacitor/storage';
import UtilityService from '@/services/utility.service';

export class StorageService
{
    public async set(key: string, value: any)
    {
        await Storage.set({
            key: key,
            value: typeof value === 'object' ? JSON.stringify(value) : value,
        });
    }

    public async get(key: string): Promise<any>
    {
        const item = await Storage.get({
            key: key
        });

        // @ts-ignore
        return UtilityService.isJsonString(item.value) ? JSON.parse(item.value) : item.value;
    }

    public async remove(key: string)
    {
        await Storage.remove({
            key: key
        });
    }

    public async clear()
    {
        await Storage.clear();
    }

    public async keys()
    {
        return await Storage.keys();
    }
}

export default new StorageService();
