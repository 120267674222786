import {Entry} from 'contentful';

export interface IAsset
{
    id?: string;
    title?: string;
    contentType?: string;
    fileName?: string;
    align?: string;
    url: string;
    details?: any;
    modal?: any;
}

export class Asset implements IAsset
{
    id?: string;
    title?: string;
    contentType?: string;
    fileName?: string;
    align?: string;
    url!: string;
    details?: any;
    modal?: any;

    static transferContentfulEntry(entry?: Entry<any>): Asset|any
    {
        const asset = new Asset();
        asset.align = 'left';

        // console.log('Asset entry', entry);
        if (entry && entry!.fields)
        {
            asset.id = entry!.sys.id;
            asset.title = entry!.fields.title;
            asset.contentType = entry!.fields.file.contentType;
            asset.fileName = entry!.fields.file.fileName;
            asset.url = entry!.fields.file.url;
            asset.details = entry!.fields.file.details;

            return asset;
        }
    }

    static setAlignment(asset, align)
    {
        if (align)
        {
            switch (align)
            {
                case 'Mitte':
                case 'mitte':
                    asset.align = 'center';
                    break;
                case 'Links':
                case 'links':
                    asset.align = 'left';
                    break;
                case 'Rechts':
                case 'rechts':
                    asset.align = 'right';
                    break;
            }
        }
        else {
            asset.align = 'left';
        }

        return asset;
    }
}
