import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_error = _resolveComponent("persona-error")!
  const _component_persona_header = _resolveComponent("persona-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_persona_form_project_request = _resolveComponent("persona-form-project-request")!
  const _component_persona_form_ist_analyse = _resolveComponent("persona-form-ist-analyse")!
  const _component_persona_form_basic = _resolveComponent("persona-form-basic")!
  const _component_persona_form_login = _resolveComponent("persona-form-login")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "ion-no-padding persona__form" }, {
    default: _withCtx(() => [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_persona_error, {
            key: 0,
            message: _ctx.error.message
          }, null, 8, ["message"]))
        : (_openBlock(), _createBlock(_component_ion_row, {
            key: 1,
            class: "ion-justify-content-between"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  (!_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_persona_header, {
                        key: 0,
                        title: _ctx.form.title,
                        "sub-title": _ctx.form.subTitle,
                        "display-logo": false,
                        "display-close-btn": _ctx.isModal,
                        "with-padding": false
                      }, null, 8, ["title", "sub-title", "display-close-btn"]))
                    : _createCommentVNode("", true),
                  (_ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_persona_header, {
                        key: 1,
                        title: "Lade...",
                        "force-sub-title": true
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, {
                size: "12",
                class: "persona__form-container"
              }, {
                default: _withCtx(() => [
                  (_ctx.getFormType() === 'projektanfrage')
                    ? (_openBlock(), _createBlock(_component_persona_form_project_request, {
                        key: 0,
                        "form-type": _ctx.form.type,
                        recipient: _ctx.recipient,
                        "form-title": _ctx.form.title
                      }, null, 8, ["form-type", "recipient", "form-title"]))
                    : _createCommentVNode("", true),
                  (_ctx.getFormType() === 'ist-analyse')
                    ? (_openBlock(), _createBlock(_component_persona_form_ist_analyse, {
                        key: 1,
                        "form-type": _ctx.form.type,
                        recipient: _ctx.recipient,
                        image: _ctx.image,
                        "form-title": _ctx.form.title
                      }, null, 8, ["form-type", "recipient", "image", "form-title"]))
                    : _createCommentVNode("", true),
                  (_ctx.getFormType() === 'kontakt')
                    ? (_openBlock(), _createBlock(_component_persona_form_basic, {
                        key: 2,
                        "form-type": _ctx.form.type,
                        recipient: _ctx.recipient,
                        "form-title": _ctx.form.title
                      }, null, 8, ["form-type", "recipient", "form-title"]))
                    : _createCommentVNode("", true),
                  (_ctx.getFormType() === 'login')
                    ? (_openBlock(), _createBlock(_component_persona_form_login, {
                        key: 3,
                        "form-type": _ctx.form.type
                      }, null, 8, ["form-type"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}