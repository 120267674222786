import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_persona_form_page = _resolveComponent("persona-form-page")!
  const _component_persona_header = _resolveComponent("persona-header")!
  const _component_persona_asset = _resolveComponent("persona-asset")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_persona_grid = _resolveComponent("persona-grid")!
  const _component_persona_icon = _resolveComponent("persona-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_ion_header, { class: "ion-no-border persona__modal-header" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar)
      ]),
      _: 1
    }),
    (_ctx.page)
      ? (_openBlock(), _createBlock(_component_ion_content, {
          key: 0,
          class: "ion-padding persona__modal-content"
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_1, [
              (_ctx.page.isForm)
                ? (_openBlock(), _createBlock(_component_persona_form_page, {
                    key: 0,
                    page: _ctx.page,
                    "is-modal": true
                  }, null, 8, ["page"]))
                : (_openBlock(), _createBlock("div", _hoisted_2, [
                    _createVNode(_component_persona_header, {
                      title: _ctx.page ? (_ctx.page.titleHyphen ? _ctx.page.titleHyphen : _ctx.page.title) : '',
                      "title-color": 'text-color',
                      displayLogo: false,
                      "display-close-btn": true,
                      "with-padding": false
                    }, null, 8, ["title"]),
                    (_ctx.page.mapAsset && _ctx.page.mapAsset.image)
                      ? (_openBlock(), _createBlock(_component_ion_grid, {
                          key: 0,
                          class: "ion-margin-bottom ion-margin-top2 persona__map-asset-grid"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_row, { class: "ion-justify-content-start" }, {
                              default: _withCtx(() => [
                                (!_ctx.page.mapAsset.modal)
                                  ? (_openBlock(), _createBlock(_component_persona_asset, {
                                      key: 0,
                                      map: _ctx.map,
                                      asset: _ctx.page.mapAsset.image
                                    }, null, 8, ["map", "asset"]))
                                  : _createCommentVNode("", true),
                                (_ctx.page.mapAsset.modal)
                                  ? (_openBlock(), _createBlock(_component_persona_asset, {
                                      key: 1,
                                      map: _ctx.map,
                                      asset: _ctx.page.mapAsset.image,
                                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal(_ctx.page.mapAsset.modal)))
                                    }, null, 8, ["map", "asset"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.page.grid)
                      ? (_openBlock(), _createBlock("div", _hoisted_3, [
                          _createVNode(_component_persona_grid, {
                            grid: _ctx.page.grid
                          }, null, 8, ["grid"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.page.mapAsset && _ctx.page.mapAsset.modal && _ctx.page.mapAsset.modal.isForm)
                      ? (_openBlock(), _createBlock(_component_ion_fab, {
                          key: 2,
                          vertical: "bottom",
                          horizontal: "end",
                          class: "ion-margin-bottom ion-margin-end"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_fab_button, {
                              color: "primary",
                              class: "ion-margin-bottom",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openModal(_ctx.page.mapAsset.modal)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_persona_icon, {
                                  name: "mail",
                                  size: "xlg"
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.page)
      ? (_openBlock(), _createBlock(_component_ion_content, {
          key: 1,
          class: "ion-padding persona__modal-content"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_persona_header, {
              title: 'Test',
              "title-color": 'text-color',
              displayLogo: false,
              "display-close-btn": true,
              "with-padding": true
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}