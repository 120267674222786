import {Entry} from 'contentful';
import {IDocument, Document} from '@/models/document.model';
import {Asset, IAsset} from '@/models/asset.model';

export class ListItem
{
    id?: string;
    title?: string;
    documents?: IDocument[];
    ref?: IAsset;

    static transferContentfulEntry(entry?: Entry<any>): ListItem|any
    {
        const listItem = new ListItem();

        // console.log('[ListItem] entry!.fields', entry!.fields);

        if (entry!.fields)
        {
            listItem.id = entry!.sys.id;
            listItem.title = entry!.fields.titel;

            if (entry!.fields.text)
            {
                const documents: Document[] = [];

                entry!.fields!.text.content.forEach((contentEle) => {
                    documents.push(Document.transferContentfulEntry(contentEle));
                })

                listItem.documents = documents;
            }

            if (entry!.fields.verweis)
            {
                listItem.ref = Asset.transferContentfulEntry(entry!.fields.verweis);
            }

            return listItem;
        }

    }
}

export class List
{
    id?: string;
    title?: string;
    items?: ListItem[];
    type: string = 'list';
    insideCard: boolean = false;

    static transferContentfulEntry(entry?: Entry<any>): List|any
    {
        const list = new List();

        // console.log('[List] entry!.fields', entry!.fields);

        if (entry!.fields)
        {
            list.id = entry!.sys.id;
            list.title = entry!.fields.titel;

            if (entry!.fields.inKarte) {
                list.insideCard = true;
            }
            if (entry!.fields.elemente)
            {
                const items: ListItem[] = [];

                entry!.fields!.elemente.forEach((contentEle) => {
                    items.push(ListItem.transferContentfulEntry(contentEle));
                })

                list.items = items;
            }

            // console.log('[List] list', list);

            return list;
        }

    }
}
