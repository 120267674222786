import {alertController} from '@ionic/vue';

export class AlertService
{
    async presentAlert(header: string, message: string)
    {
        const alert = await alertController
        .create({
            cssClass: 'alert__lg',
            header: header,
            message: message,
            buttons: ['OK'],
        });

        const res = await alert.present();
        // await alert.onDidDismiss();

        return res;
    }
}

export default new AlertService();

