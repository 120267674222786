import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import SessionService from '@/services/session.service';
import AnalyticsService from '@/services/analytics.service';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login/LoginPage.vue')
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: () => import('@/pages/dashboard/DashboardPage.vue'),
    props: true
  },
  {
    path: '/forms/project-request',
    name: 'FormProjectRequestPage',
    component: () => import('@/pages/form-project-request/FormProjectRequestPage.vue')
  },
  {
    path: '/:id',
    name: 'DetailPage',
    component: () => import('@/pages/detail/DetailPage.vue'),
    props: {
      topic: {}
    }
  },
  {
    path: '/topics/:id',
    name: 'TabsPage',
    component: () => import('@/pages/tabs/TabsPage.vue'),
    props: {
      topic: {}
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const isAuthenticated = SessionService.isAuthenticated();
  // console.log('isAuthenticated', isAuthenticated)
  if (to.name !== 'Login' && !isAuthenticated) {
    next({ name: 'Login' });
  }
  else if (to.name === 'Login' && isAuthenticated){
    console.log('[Router] User is logged in, redirect to dashboard')
    next({path: '/dashboard', params: {origin: 'login' }});
  }
  else {
    next();
  }
})

router.afterEach((to, from, next) => {
  // console.log('[Router::afterEach]', to);
  AnalyticsService.trackRouteChange(to);
})

export default router

