import {Asset, IAsset} from '@/models/asset.model';
import {Entry} from 'contentful';
import {IDocument, Document} from '@/models/document.model';
import {IPage, Page} from '@/models/page.model';

export class Card
{
    id?: string;
    title?: string;
    subTitle?: string;
    text?: string;
    documents?: IDocument[];
    image?: IAsset;
    type: string = 'card';
    modal?: IPage;
    hasModal: boolean = false;

    static transferContentfulEntry(entry?: Entry<any>): Card|any
    {
        const card = new Card();

        // console.log('[Card] entry!.fields', entry!.fields);

        if (entry!.fields)
        {
            card.id = entry!.sys.id;
            card.title = entry!.fields.titel;
            card.subTitle = entry!.fields.untertitel;

            if (entry!.fields.text)
            {
                const documents: Document[] = [];

                entry!.fields!.text.content.forEach((contentEle) => {
                    documents.push(Document.transferContentfulEntry(contentEle));
                })

                card.documents = documents;
            }

            if (entry!.fields.inhalt)
            {
                card.text = entry!.fields.inhalt;
            }

            if (entry!.fields.bild)
            {
                card.image = Asset.transferContentfulEntry(entry!.fields.bild);
            }

            if (entry!.fields.modal)
            {
                card.modal = Page.transferContentfulEntry(entry!.fields.modal);
                card.hasModal = true;
            }

            // console.log('[Card] card', card);

            return card;
        }

    }
}
