
import {defineComponent} from 'vue';
import {Asset} from '@/models/asset.model';

export default defineComponent( {
    name: 'Asset',
    props: {
        asset: {
            type: Asset,
            required: true
        },
        alt: {
            type: String,
            default: ''
        },
        fit: {
            type: String
        },
        align: {
            type: String
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        quality: {
            type: Number,
            default: 100
        },
        asBackground: {
            type: Boolean,
            default: false
        },
        fadeIn: {
            type: Boolean,
            default: false
        },
        map: {
            type: Object
        }
    },
    methods: {
        test(area) {
          if (area.onClick)
          {
              area.onClick(area);
          }
        },
        params() {
            let params = '';

            if (this.fit)
            {
                params += `fit=${this.fit}`
            }

            if (this.width)
            {
                const prefix = params.length ? '&' : '';
                params += `${prefix}w=${this.width}`
            }

            if (this.height)
            {
                const prefix = params.length ? '&' : '';
                params += `${prefix}h=${this.height}`
            }

            if (this.quality)
            {
                const prefix = params.length ? '&' : '';
                params += `${prefix}q=${this.quality}`
            }

            return `?${params}`
        },
        url()
        {
            return this.asset.url + this.params();
        },
        style()
        {
            return 'background-image: url(' + this.asset.url + this.params() + '); background-size: cover; background-repeat: no-repeat; background-position: center; height: 100%; width: 100%;';
        }
    }
})
