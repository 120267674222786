import {Topic} from '@/models/topic.model';
import AuthService from '@/services/auth.service';
import StorageService from '@/services/storage.service';

export class SessionService
{
    private currentTopic!: Topic;

    setCurrentTopic(topic: Topic)
    {
        this.currentTopic = topic;
    }

    getCurrentTopic()
    {
        return this.currentTopic;
    }

    getCurrentUser()
    {
        return AuthService.account;
    }

    isAuthenticated()
    {
        return AuthService.isLoggedIn;
    }

    logout()
    {
        StorageService.clear();
        AuthService.logout();
    }

}

export default new SessionService();
