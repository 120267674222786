
import {defineComponent} from 'vue';
import {IonRow, IonCol, IonText, modalController} from '@ionic/vue';
import PersonaParagraph from '@/components/paragraph/Paragraph.vue';
import {Page} from '@/models/page.model';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';

export default defineComponent({
    name: 'Paragraph',
    components: {
        IonRow,
        IonText,
        IonCol,
        PersonaParagraph
    },
    props: {
        paragraphs: {
            type: Object,
            required: true
        }
    },
    setup()
    {
        async function openModal(modalPage?: Page)
        {
            // console.log('[Paragraph] openModal', modalPage)

            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: modalPage
                },
            });

            return modal.present();
        }

        const onClick = (paragraph) =>
        {
            if (paragraph.modal)
            {
                // console.log('paragraph', paragraph.modal);
                openModal(paragraph.modal);
            }
        }

        return { onClick };
    }
});
