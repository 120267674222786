import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_main_menu = _resolveComponent("persona-main-menu")!
  const _component_persona_fab_menu = _resolveComponent("persona-fab-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { class: _ctx.classes }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          _createVNode(_component_persona_main_menu, {
            side: "end",
            "menu-id": "main",
            onMainMenuWillClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mainMenuWillClose($event))),
            onMainMenuWillOpen: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mainMenuWillOpen($event)))
          })
        ]),
        _: 1
      })),
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          _createVNode(_component_persona_main_menu, {
            side: "start",
            "menu-id": "secondary",
            onSecondaryMenuWillClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.secondaryMenuWillClose($event))),
            onSecondaryMenuWillOpen: _cache[4] || (_cache[4] = ($event: any) => (_ctx.secondaryMenuWillOpen($event)))
          })
        ]),
        _: 1
      })),
      _createVNode(_component_persona_fab_menu, {
        "main-menu-is-open": _ctx.mainMenuIsOpen,
        "secondary-menu-is-open": _ctx.secondaryMenuIsOpen
      }, null, 8, ["main-menu-is-open", "secondary-menu-is-open"]),
      _createVNode(_component_ion_router_outlet, { id: "main" })
    ]),
    _: 1
  }, 8, ["class"]))
}