import {ITopic} from '@/models/topic.model';
import StorageService from '@/services/storage.service';
import {STORAGE_KEYS} from '@/models/app.model';
// import {Page} from '@/models/page.model';
export class TopicService
{
    getMockedTopics(): ITopic[]
    {
        return [
            {
                id: '0',
                cover: {
                    url: '/assets/content/dashboard/kundenbranche.jpg',
                },
                title: 'Branchenkompetenz',
                subTitle: 'Lorem Ipsum',
                path: '/branchenkompetenz'
            },
            {
                id: '1',
                cover: {
                    url: '/assets/content/dashboard/portfolio.jpg'
                },
                title: 'Portfolio',
                subTitle: 'Lorem Ipsum',
                path: '/portfolio'
            },
            {
                id: '2',
                cover: {
                    url: '/assets/content/dashboard/warum_persona_service.jpg'
                },
                title: 'Warum mit persona service?',
                subTitle: 'Lorem Ipsum',
                path: '/warum-mit-persona-service'
            },
            {
                id: '3',
                cover: {
                    url: '/assets/content/dashboard/unternehmensverbund.jpg'
                },
                title: 'Unternehmens-verbund',
                path: '/unternehmensverbund',
                subTitle: 'Lorem Ipsum'
            },
            {
                id: '4',
                cover: {
                    url: '/assets/content/dashboard/auftrag.jpg'
                },
                title: 'Auftrag',
                path: '/auftrag',
                subTitle: 'Lorem Ipsum'
            },
            {
                id: '5',
                cover: {
                    url: '/assets/content/dashboard/hr4you.jpg'
                },
                title: 'Bewerbermanagementsystem',
                path: '/bewerbermanagementsystem',
                subTitle: 'Lorem Ipsum'
            },
            {
                id: '5',
                cover: {
                    url: '/assets/content/dashboard/sonderthema.jpg'
                },
                title: 'Projektanfrage',
                path: '/projektanfrage',
                subTitle: 'Lorem Ipsum'
            }
        ];
    }

    async getTopicByPath(path: string)
    {
        const dashboard = await StorageService.get(STORAGE_KEYS.DASHBOARD);

        if (dashboard && dashboard.topics)
        {
            const topic = dashboard.topics.find((actTopic) => {
                // console.log(actTopic.path, path)
                path = path.replace('topics/', '');
                return actTopic.path === path.replace('/', '');
            });

            if (topic && topic.pages)
            {
                // topic.pages = topic.pages.filter((page: Page) => page.hideInTabs === false);
            }

            // console.log('[TopicService] getTopicByPath dashboard.topics', dashboard.topics);
            // console.log('[TopicService] getTopicByPath topic', topic);

            return topic;
        }
    }

    getTabIndex(topic, query)
    {
        if (query)
        {
            // @ts-ignore Property 'pages' does not exist on type 'never'
            const pages = topic.pages;

            const act = pages.find((actPage) => {
                return actPage.path === query;
            })

            if (act)
            {
                return pages.indexOf(act);
            }
        }
    }

}

export default new TopicService()
