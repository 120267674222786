import {ITopic, Topic} from '@/models/topic.model';
import {Entry} from 'contentful';
import {IContentfulEntity} from '@/models/contentfulEntity.model';

export interface IDashboard
{
    id: string;
    title: string;
    subTitle: string;
    topics: Array<ITopic>;
}

export class Dashboard implements IDashboard, IContentfulEntity
{
    id!: string;
    title!: string;
    subTitle!: string;
    topics!: Array<ITopic>;

    static transferContentfulEntry(entry?: Entry<any>)
    {
        const dashboard = new Dashboard();

        if (entry)
        {
            const fields = entry.fields;
            const topics = fields.themen;
            // console.log('topics', topics);

            dashboard.id = entry!.sys.id;
            dashboard.title = fields.titel;
            dashboard.subTitle = fields.subtitel;
            dashboard.topics = [];

            topics.forEach((topic) => {
                const actTopic = Topic.transferContentfulEntry(topic);
                // console.log('actTopic', topic, actTopic)
                if (actTopic)
                {
                    dashboard.topics.push(actTopic);
                }
            })
        }

        return dashboard;
    }
}
