import mitt, { Emitter } from 'mitt';

export type Events = {
    foo: string;
    bar?: number;
};

export class EventService
{
    protected _emitter: Emitter<any>;

    constructor()
    {
        this._emitter = mitt<any>();
    }

    emit(event, data)
    {
        this._emitter.emit(event, data);
    }

    get emitter()
    {
        return this._emitter;
    }
}

export default new EventService();

