import {Asset} from '@/models/asset.model';
import {Entry} from 'contentful';
import {IContentfulEntity} from '@/models/contentfulEntity.model';
import {Page} from '@/models/page.model';

export enum STORAGE_KEYS
{
    DASHBOARD = 'dashboard',
    APP = 'app',
    IS_ONLINE = 'is_online',
    TOKEN = 'token',
    FORM_IS_ANALYSE = 'form_is_analyse'
}

export enum EVENTS
{
    MAIN_MENU_WILL_CLOSE = 'mainMenuWillClose',
    MAIN_MENU_WILL_OPEN = 'mainMenuWillOpen',
    SECONDARY_MENU_WILL_CLOSE = 'secondaryMenuWillClose',
    SECONDARY_MENU_WILL_OPEN = 'secondaryMenuWillOpen',
    ACCESS_TOKEN_READY = 'accessTokenReady',
    TOPIC_CHANGED = 'topicChanged',
}
export interface IApp
{
    title: string;
    logo: Asset;
    form?: Page;
}

export class App implements IApp, IContentfulEntity
{
    id!: string;
    title!: string;
    logo!: Asset;
    form?: Page;
    analyses?: Page[];

    static transferContentfulEntry(entry?: Entry<any>): App
    {
        const app = new App();
        if (entry)
        {
            app.id = entry!.sys.id;
            app.title = entry!.fields.titel;

            if (entry!.fields.logo)
            {
                app.logo = Asset.transferContentfulEntry(entry!.fields.logo);
            }

            if (entry!.fields.formular)
            {
                app.form = Page.transferContentfulEntry(entry!.fields.formular);
            }

            if (entry!.fields.analysen)
            {
                const analyses: Array<Page> = [];
                entry!.fields.analysen.forEach((page) => {
                    const actPage = Page.transferContentfulEntry(page);
                    if (actPage)
                    {
                        analyses.push(actPage);
                    }
                });
                app.analyses = analyses
            }
        }

        // console.log('[App::transferContentfulEntry] app', app.analyses)

        return app;
    }
}
