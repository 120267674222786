
import {IonCol, IonRow, IonText, modalController} from '@ionic/vue';
import {defineComponent, ref, toRefs} from 'vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import {IAsset} from '@/models/asset.model';
import {Grid} from '@/models/grid.model';
import PersonaDocument from '@/components/document/Document.vue';

export default defineComponent({
    name: 'Grid',
    components: {
        IonCol,
        IonRow,
        IonText,
        PersonaAsset,
        PersonaDocument
    },
    props: {
        grid: {
            type: Grid,
            required: true
        }
    },
    setup(props) {

        const { grid } = toRefs(props);

        const modalIsOpen = ref(false);
        async function openModal(asset: IAsset)
        {
            console.log('[ImageGrid] openModal', asset)

            if (asset.modal)
            {
                const modalPage = asset.modal;


                const modal = await modalController
                .create({
                    component: ModalPage,
                    cssClass: 'modal__full',
                    componentProps: {
                        page: modalPage
                    },
                });

                modal.onWillDismiss().then(
                    () => {
                        modalIsOpen.value = !modalIsOpen.value;
                    }
                )

                return modal.present().then(
                    () => {
                        modalIsOpen.value = !modalIsOpen.value;
                    }
                )
            }
        }
        // const map = AssetService.buildMap(page, openModal);

        function colClasses(col)
        {
            let classes = '';
            classes += ' ion-text-' + col.align;
            classes += ' ion-align-items-' + col.align;
            classes += ' ion-justify-content-' + col.justify;

            // console.log('classes', classes)
            return classes;
        }

        return {  modalIsOpen, openModal, colClasses }
    }
});
