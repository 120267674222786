
import {defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import {menuController, modalController} from '@ionic/vue';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import AppService from '@/services/app.service';
import StorageService from '@/services/storage.service';
import {EVENTS, STORAGE_KEYS} from '@/models/app.model';
import {useRoute, useRouter} from 'vue-router';
import SessionService from '@/services/session.service';
import useEmitter from '@/composables/useEmitter';
import AnalyticsService from '@/services/analytics.service';

export default defineComponent( {
    name: 'FabMenu',
    components: {
        PersonaIcon
    },
    props: {
        mainMenuIsOpen: {
            type: Boolean,
            default: false
        },
        secondaryMenuIsOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            homeButton: {
                bgColor: 'light',
                iconName: 'windows',
            },
            menuButton: {
                bgColor: 'light',
                iconName: 'more',
                iconSize: 'sm'
            },
            checkMenuButton: {
                bgColor: 'light',
                iconName: 'check',
                iconSize: 'lg'
            },
            checkButton: {
                bgColor: 'light',
                iconName: 'check',
                iconSize: 'lg'
            },
            loggedIn: false
        }
    },
    setup()
    {
        const route = useRoute();
        const isRedirectFromLogin = route!.redirectedFrom ? route!.redirectedFrom.name === 'Login' : false;

        let formPage = ref(null);
        const appRepository = async () =>
        {
            let app = await StorageService.get(STORAGE_KEYS.APP);
            if (!app)
            {
                // after login it take some seconds to get the access token, so we need to wait until the token is ready
                if (isRedirectFromLogin)
                {
                    const emitter = useEmitter();
                    emitter.on(EVENTS.ACCESS_TOKEN_READY, async () => {
                        app = await AppService.getApp();
                    });
                }
                else{
                    app = await AppService.getApp();
                }
            }

            if (app)
            {
                // formPage = app.analyses[0];
                formPage = app.form;
            }
        }
        onMounted(appRepository)

        async function toggleCheck()
        {
            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: formPage
                },
            });

            return modal.present();
        }

        return { toggleCheck };
    },
    methods: {
        checkLogin()
        {
            return SessionService.isAuthenticated();
        },
        isDisabled() {
            const currentRouteIsDashboard = this.$route.path === '/dashboard';
            if (currentRouteIsDashboard){
                this.homeButton.bgColor = 'medium';
                this.homeButton.iconName = 'windows__white';
            }
            else{
                this.homeButton.bgColor = 'light';
                this.homeButton.iconName = 'windows';
            }

            return currentRouteIsDashboard
        },
        openDashboard() {
            AnalyticsService.trackEvent('home_button_clicked', null, 'App', 'Home Button geklickt');

            // TODO Optimize
            // if there were multiple pushs, back not directly opens dashboard
            if (window.history.state.back === '/dashboard')
            {
                this.$router.back();
            }
            else{
                window.location.href = '/dashboard';
            }
        },
        toggleMenu(id: string = 'main') {
            // console.log('[FabMenu::toggleMenu] id', id)
            menuController.toggle(id);
        },
        getMenuButtonBgColor() {
            if (this.mainMenuIsOpen){
                this.menuButton.bgColor = 'primary';
                this.menuButton.iconName = 'close__white';
                this.menuButton.iconSize = 'lg';
            }
            else{
                this.menuButton.bgColor = 'light';
                this.menuButton.iconName = 'more';
                this.menuButton.iconSize = 'sm';
            }

            this.$forceUpdate();
        },
        getCheckMenuButtonBgColor() {
            // console.log('secondaryMenuIsOpen', this.secondaryMenuIsOpen)
            if (this.secondaryMenuIsOpen){
                this.checkMenuButton.bgColor = 'primary';
                this.checkMenuButton.iconName = 'close__white';
                this.checkMenuButton.iconSize = 'lg';
            }
            else{
                this.checkMenuButton.bgColor = 'light';
                this.checkMenuButton.iconName = 'check';
                this.checkMenuButton.iconSize = 'lg';
            }

            this.$forceUpdate();
        }
    },
    watch: {
        mainMenuIsOpen: {
            handler: function() {
                this.getMenuButtonBgColor();
            },
            deep: true
        },
        secondaryMenuIsOpen: {
            handler: function() {
                this.getCheckMenuButtonBgColor();
            },
            deep: true
        },
        '$route' (to, from){
            // this fab menu should not be displayed on the login page
            this.checkLogin();
            this.$forceUpdate();
        }
    }
});
