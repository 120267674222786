import {IContentfulEntity} from '@/models/contentfulEntity.model';
import {Asset, IAsset} from '@/models/asset.model';
import {Page} from '@/models/page.model';
import {Card} from '@/models/card.model';
import {Button} from '@/models/button.model';
import {List} from '@/models/list.model';

export interface IParagraph
{
    value?: any;
    type: string;
    mark?: string;
    mapAsset?: any;
    modal?: any;
    paragraphs?: any;
}

export interface IDocument
{
    id: string;
    paragraphs?: IParagraph[];
    assets?: IAsset[];
    entries?: any[];
    list?: any;
}

export class Document implements IDocument, IContentfulEntity
{
    id!: string;
    paragraphs?: IParagraph[];
    assets?: IAsset[];
    entries?: any[];
    list?: any;

    static transferContentfulEntry(entry?: any): Document
    {
        const document = new Document();

        // console.log('Document', entry, entry.nodeType);

        let paragraphs: IParagraph[] = [];
        const assets: IAsset[] = [];
        const entries: any[] = [];

        switch (entry.nodeType)
        {
            case 'unordered-list':
                // console.log('Document', entry.content);
                if (entry.content)
                {
                    const list: any = {items: [], type: 'unordered'};
                    entry.content.forEach((item: any) => {

                        switch (item.nodeType)
                        {
                            case 'list-item':
                                // eslint-disable-next-line no-case-declarations
                                const paragraphs = [];
                                // console.log('Document item', item);

                                item.content.forEach((paragraph: any) => {
                                    // @ts-ignore
                                    paragraphs.push(Document.transferParagraphs(paragraph.content));
                                });
                                list.items.push({paragraphs});
                                break;
                        }
                    })
                    document.list = list;
                    // console.log('Document list', list);
                }
                break;

            case 'paragraph':
                paragraphs = Document.transferParagraphs(entry.content);
                break;

            case 'embedded-asset-block':
                // console.log('embedded-asset-block',  entry.data.target.fields.file);
                assets.push(Asset.transferContentfulEntry(entry.data.target));
                break;

            case 'embedded-entry-block':
                // console.log('[Document] entry', entry.data.target.fields);
                // eslint-disable-next-line no-case-declarations
                const entryType = entry.data.target.sys.contentType.sys.id;
                switch (entryType)
                {
                    case 'karte':
                        // eslint-disable-next-line no-case-declarations
                        const card = Card.transferContentfulEntry(entry.data.target);
                        entries.push(card);
                        break;
                    case 'button':
                        // eslint-disable-next-line no-case-declarations
                        const button = Button.transferContentfulEntry(entry.data.target);
                        entries.push(button);
                        break;
                    case 'liste':
                        // eslint-disable-next-line no-case-declarations
                        const list = List.transferContentfulEntry(entry.data.target);
                        entries.push(list);
                        break;
                    default:
                        console.log('[Document] entryType', entryType);
                        break;
                }

                break;

            default:
                console.log('[Document] Default entry TODO', entry, entry.nodeType);
                break;
        }

        document.paragraphs = paragraphs;
        document.assets = assets;
        document.entries = entries;

        return document;
    }

    static transferParagraphs(content, push: boolean = true)
    {
        const paragraphs: IParagraph[] = [];
        if (content)
        {
            // console.log('content', content)
            content.forEach((paragraph: any) => {
                // console.log('paragraph', paragraph.nodeType);
                switch (paragraph.nodeType)
                {
                    case 'text':
                        // eslint-disable-next-line no-case-declarations
                        const act: IParagraph = {value: paragraph.value, type: paragraph.nodeType};
                        if (paragraph.marks && paragraph.marks.length)
                        {
                            act.mark = paragraph.marks[0].type;
                        }
                        // console.log('paragraph.value', paragraph.value.length)
                        // if (paragraph.value.length)
                        // {
                        //     paragraphs.push(act);
                        // }
                        paragraphs.push(act);
                        break;

                    case 'entry-hyperlink':
                        // console.log('hyperlink paragraph data', paragraph.data.target.fields);

                        // eslint-disable-next-line no-case-declarations
                        const linkParagraph: IParagraph = {value: paragraph.value, type: paragraph.nodeType};

                        // eslint-disable-next-line no-case-declarations
                        const subParagraphs: IParagraph[] = [];
                        paragraph.content.forEach((paragraph: any) => {
                            // eslint-disable-next-line no-case-declarations
                            const act: IParagraph = {value: paragraph.value, type: paragraph.nodeType};
                            if (paragraph.marks && paragraph.marks.length)
                            {
                                act.mark = paragraph.marks[0].type;
                            }
                            subParagraphs.push(act);
                        });

                        linkParagraph.paragraphs = subParagraphs;


                        if (paragraph.data.target)
                        {
                            // console.log('hyperlink paragraph.data.target', paragraph.data.target.fields);

                            const modal = Page.transferContentfulEntry(paragraph.data.target);
                            if (modal)
                            {
                                modal.layout = 'modal';
                                linkParagraph.modal = modal;
                            }
                        }

                        paragraphs.push(linkParagraph);


                        // console.log('hyperlink linkParagraph', linkParagraph);
                        break;
                }

            })
        }

        return paragraphs;
    }
}
