
import {defineComponent, ref, toRefs} from 'vue';
import {IonRow, IonCol, IonItem, IonCheckbox, IonLabel, IonText} from '@ionic/vue';
import {Form} from 'vee-validate';
import PersonaFormInput from '@/components/form/form-input/FormInput.vue';
import PersonaFormTextarea from '@/components/form/form-textarea/FormTextarea.vue';
import PersonaFormCheckbox from '@/components/form/form-checkbox/FormCheckbox.vue';
import {FormTransfer, IForm} from '@/models/form.model';
import AlertService from '@/services/alert.service';
import PersonaAsset from '@/components/asset/Asset.vue';
import yupLocaleDe from '@/i18n/de.yupLocale';
import StorageService from '@/services/storage.service';
import SessionService from '@/services/session.service';
import {STORAGE_KEYS} from '@/models/app.model';
import BolProvider from '@/provider/bol.provider';
import * as yup from 'yup';
import AnalyticsService from '@/services/analytics.service';

yup.setLocale(yupLocaleDe);

export default defineComponent({
    name: 'FormIsAnalyse',
    components: {
        PersonaFormInput,
        PersonaFormTextarea,
        PersonaFormCheckbox,
        PersonaAsset,
        Form,
        IonLabel,
        IonText,
        IonCheckbox,
        IonRow,
        IonCol,
        IonItem
    },
    props: {
        formTitle: {
            type: String,
            required: false
        },
        formType: {
            type: String,
            required: true
        },
        image: {
            type: Object,
            required: false
        },
        recipient: {
            type: Object,
            required: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: 600,
            required: false
        }
    },
    setup(props) {

        const { formType, formTitle, image, maxLength } = toRefs(props);

        const length = maxLength && maxLength.value ? maxLength.value : 0;

        const schema = yup.object({
            text: yup.string().label('Freitext').required().max(length, 'Es sind max. '+length+' Zeichen erlaubt')
        });

        const form: IForm = {};
        form.title = formTitle.value;
        form.type = formType.value;

        const account = SessionService.getCurrentUser();
        if (account)
        {
            form.recipient = {
                email: account.userName,
                name: account.name
            }
        }

        form.textareas = [
            {name: 'text', label: '', required: true, value: '', placeholder: 'Freitext (max. '+length+' Zeichen) ...', rows: 4}
        ];

        const formValues = ref({text: ''});
        async function getStorageText()
        {
            const text = await StorageService.get(STORAGE_KEYS.FORM_IS_ANALYSE);
            if (text)
            {
                formValues.value.text = text;
            }
        }
        getStorageText();

        function onSubmit(values: any, { resetForm })
        {
            // form.recipient.email = 's.kroell@labor.digital';

            const formTransfer = FormTransfer.create(form, values);

            const request = BolProvider.sendForm(formTransfer);
            if (request)
            {
                request.then(() => {

                    AnalyticsService.trackFormEvent(formTransfer);

                    // console.log('[FormIsAnalyse] form send');
                    AlertService.presentAlert('Versendet', 'Das Formular wurde erfolgreich an "' + form.recipient.email + '" versendet.').then(() => {
                        StorageService.remove(STORAGE_KEYS.FORM_IS_ANALYSE);
                        resetForm();
                    });
                });
            }
        }

        function onInvalidSubmit({ values, errors, results })
        {
            AnalyticsService.trackFormEvent({type: 'ist-analyse', title: 'IST-Analyse'}, 'error');
            AlertService.presentAlert('Nicht versendet', 'Das Formular konnte nicht versendet werden, bitte erneut versuchen.');
        }

        async function onTextChanged(text)
        {
            await StorageService.set(STORAGE_KEYS.FORM_IS_ANALYSE, text);
        }

        return { form, onSubmit, onInvalidSubmit, schema, onTextChanged, formValues }
    }
});
