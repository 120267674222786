import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "persona__paragraph"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.paragraphs)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.paragraphs, (paragraph, paragraphKey) => {
          return (_openBlock(), _createBlock("span", {
            class: ["text flex-none", {'font-bold': paragraph.mark === 'bold', 'text-underline': paragraph.mark === 'underline', 'font-italic': paragraph.mark === 'italic'}],
            key: paragraphKey
          }, [
            (paragraph.paragraphs && paragraph.paragraphs.length)
              ? (_openBlock(), _createBlock("span", _hoisted_2, [
                  (paragraph.type === 'entry-hyperlink')
                    ? (_openBlock(), _createBlock("span", _hoisted_3, [
                        _createVNode("a", {
                          onClick: ($event: any) => (_ctx.onClick(paragraph))
                        }, [
                          (_openBlock(true), _createBlock(_Fragment, null, _renderList(paragraph.paragraphs, (subParagraph, subParagraphKey) => {
                            return (_openBlock(), _createBlock("span", {
                              class: ["text flex-none", {'font-bold': subParagraph.mark === 'bold', 'text-underline': subParagraph.mark === 'underline', 'font-italic': subParagraph.mark === 'italic'}],
                              key: subParagraphKey
                            }, _toDisplayString(subParagraph.value), 3))
                          }), 128))
                        ], 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock("span", _hoisted_4, _toDisplayString(paragraph.value), 1))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}