
import {defineComponent, toRefs} from 'vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import {modalController} from '@ionic/vue';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';

export default defineComponent({
    name: 'Button',
    components: {
        PersonaIcon
    },
    props: {
        button: {
            type: Object
        }
    },
    setup(props)
    {
        const { button } = toRefs(props);

        async function openModal(modalPage)
        {
            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: modalPage
                },
            });
            return modal.present();
        }

        const onClick = () => {
            // @ts-ignore
            openModal(button.value.modal)
        }

        return { onClick };
    }
});
