import axios, {AxiosRequestConfig} from 'axios';
import AuthService from '@/services/auth.service';
import {STORAGE_KEYS} from '@/models/app.model';
import StorageService from '@/services/storage.service';
import ContentfulProvider from '@/provider/contentful.provider';
import AlertService from '@/services/alert.service';

export class BolProvider
{
    private config?: AxiosRequestConfig|any = {};
    private isOnline: boolean = true;

    private initClient()
    {
        const token = AuthService.token;
        // console.log('[BolProvider::initClient] token', token)

        if (token)
        {
            this.config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                // validateStatus: function (status) {
                //     return status >= 200 && status < 400
                // }
            }
        }

        this.isOnline = localStorage.getItem(STORAGE_KEYS.IS_ONLINE) === 'true';
    }

    getContentfulEntries(limit: number = 600): Promise<any>
    {
        // console.log('[BolProvider::getContentfulEntries] isLoggedIn', AuthService.isLoggedIn)

        if (this.isOnline && AuthService.isLoggedIn)
        {
            if (process.env.VUE_APP_CONTEXT === 'development')
            {
                return ContentfulProvider.getEntries({limit: limit}).then((response) => {
                    return ContentfulProvider.parseEntries(response);
                })
            }
            else{
                this.initClient();

                return axios.get(process.env.VUE_APP_BOL_API_URL + process.env.VUE_APP_BOL_API_ENDPOINT_ENTRIES + '?limit=' + limit, this.config).then((response: { data: any }) => {
                    return ContentfulProvider.parseEntries(response.data);
                }).catch((err) => {
                    if (err.response.status === 401) {
                        AuthService.refreshToken();
                    }
                    console.log(JSON.stringify(err));
                })
            }
        }
        else {
            return new Promise<any>((resolve) => {
                resolve();
            });
        }
    }

    syncContenful(initial: boolean = true, contentType?: string, type?: string)
    {
        this.initClient();

        this.config.params = {initial, limit: 400};

        if (contentType)
        {
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.config.params.content_type = contentType;
        }

        if (type)
        {
            this.config.params.type = type;
        }

        if (this.isOnline)
        {
            return axios.get(process.env.VUE_APP_BOL_API_URL + process.env.VUE_APP_BOL_API_ENDPOINT_SYNC, this.config).then((response: { data: any }) => {
                console.log('[BolProvider::syncContenful]', response.data);
                // return response.data;
                return ContentfulProvider.parseEntries(response.data);
            }).catch((err) => {
                console.error(err);
            })
        }
        else {
            return StorageService.get(STORAGE_KEYS.DASHBOARD);
        }
    }

    sendForm(data)
    {
        if (this.isOnline)
        {
            this.initClient();

            return axios.post(process.env.VUE_APP_BOL_API_URL + process.env.VUE_APP_BOL_API_ENDPOINT_FORM, data, this.config).then((response: { data: any }) => {
                // console.log('[BolProvider::sendForm]', response.data);
                return response.data;
            }).catch((err) => {
                console.error(err);
            })
        }
        else {
            AlertService.presentAlert('Offline', 'Bitte eine Internetverbindung herstellen, um das Formular absenden zu können.');
        }
    }
}

export default new BolProvider();
