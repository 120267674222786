
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import PersonaMainMenu from '@/components/main-menu/MainMenu.vue'
import PersonaFabMenu from '@/components/fab-menu/FabMenu.vue'
import { Network } from '@capacitor/network';
import AlertService from '@/services/alert.service';
import {STORAGE_KEYS} from '@/models/app.model';
import UtilityService from '@/services/utility.service';
import { Device } from '@capacitor/device';

import EtrackerService from '@/services/etracker.service';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        PersonaMainMenu,
        PersonaFabMenu
    },
    setup()
    {
        EtrackerService.initialize();

        // console.log('[App] env', process.env);
        // console.log('[App] env', process.env.NODE_ENV);

        const isOnline = localStorage.getItem(STORAGE_KEYS.IS_ONLINE);
        if (isOnline == undefined)
        {
            localStorage.setItem(STORAGE_KEYS.IS_ONLINE, 'true');
        }

        Network.addListener('networkStatusChange', status => {
            console.log('[App] Network status changed', status);
            if (!status.connected){
                // alert('Offline. Aktuell besteht keine Verbindung zum Internet');
                // AlertService.presentAlert('Offline', 'Aktuell besteht keine Verbindung zum Internet')
                localStorage.setItem(STORAGE_KEYS.IS_ONLINE, 'false');
            }
            else{
                AlertService.presentAlert('Online', 'Verbindung zum Internet wurde wieder hergestellt');
                localStorage.setItem(STORAGE_KEYS.IS_ONLINE, 'true');
            }
        });

        const classes = ref('');

        const createClasses = async () => {
            const zoomFactor = UtilityService.zoomFactor();
            switch (zoomFactor)
            {
                case 150:
                    classes.value = 'persona__zoom-150';
                    break;
                case 125:
                    classes.value = 'persona__zoom-125';
                    break;
                default:
                    classes.value = 'persona__zoom-default';
                    break;
            }

            const deviceInfo = await Device.getInfo();
            classes.value += ' persona__system-' + deviceInfo.operatingSystem;
        };
        createClasses();

        return { classes };
    },
    data() {
        return {
            mainMenuIsOpen: false,
            secondaryMenuIsOpen: false,
        }
    },
    methods: {
        mainMenuWillOpen() {
            this.mainMenuIsOpen = true;
        },
        mainMenuWillClose() {
            this.mainMenuIsOpen = false;
        },
        secondaryMenuWillOpen() {
            this.secondaryMenuIsOpen = true;
        },
        secondaryMenuWillClose() {
            this.secondaryMenuIsOpen = false;
        }
    }
});
