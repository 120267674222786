import ContentfulProvider from '@/provider/contentful.provider';
import {App, IApp} from '@/models/app.model';
import BolProvider from '@/provider/bol.provider';

export class AppService
{
    getApp(): Promise<IApp|any>
    {
        return BolProvider.getContentfulEntries().then((res) => {

            // console.log('[AppService] res', res);

            if (res && res.items)
            {
                const items = res.items;

                const app = items.find((item) => {
                    return item.sys.contentType.sys.id === 'app';
                });

                // console.log('[AppService] res', app);

                return App.transferContentfulEntry(app);
            }
            else {
                return res;
            }
        });
    }

    getAppViaContentful(): Promise<IApp|any>
    {
        // console.log('[AppService::getAppViaContentful] ');

        // eslint-disable-next-line @typescript-eslint/camelcase
        return ContentfulProvider.sync({initial: true, type: 'all'}).then((res) => {

            console.log('[AppService::getAppViaContentful] res before', res);

            // const items = res.items;
            //
            // const app = items.find((item) => {
            //     return item.sys.contentType.sys.id === 'app';
            // });
            //
            // return App.transferContentfulEntry(app);
        });
    }
}

export default new AppService();
