<template>

    <ion-page >

        <ion-header :translucent="translucent" :class="{'ion-no-border': hideHeaderBorder}">
            <ion-toolbar color="transparent">
<!--                <ion-buttons slot="start">-->
<!--                    <ion-back-button :default-href="pageDefaultBackLink" :text="pageDefaultBackTitle"></ion-back-button>-->
<!--                </ion-buttons>-->
                <ion-title>{{ pageTitle }}</ion-title>
                <ion-buttons slot="end">
                    <slot name="header-buttons-end"></slot>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="fullscreen" :class="{'ion-padding': contentPadding}">
            <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
                <ion-refresher-content  :pulling-icon="arrowDownOutline" pulling-text="Ziehen zum Aktualisieren" refreshing-spinner="circular"></ion-refresher-content>
            </ion-refresher>
            <div class="container">
                <slot></slot>
            </div>
        </ion-content>

    </ion-page>

</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonRefresher, IonRefresherContent } from '@ionic/vue';
import {defineComponent} from 'vue';
import { arrowDownOutline } from 'ionicons/icons';
import ContentfulService from "@/services/contentful.service";

export default defineComponent({
    name: 'BaseLayout',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton,
        IonRefresher, IonRefresherContent
    },
    props: {
        pageTitle: {
            type: String,
            required: false
        },
        pageDefaultBackLink: {
            type: String,
            required: false
        },
        pageDefaultBackTitle: {
            type: String,
            required: false
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        translucent: {
            type: Boolean,
            default: false
        },
        hideHeaderBorder: {
            type: Boolean,
            default: true
        },
        contentPadding: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const doRefresh = async (event) => {
            await ContentfulService.getData();

            // without it is too fast :)
            const t = setTimeout(() => {
                event.target.complete();
                clearTimeout(t);
            }, 400);
        }
        return { arrowDownOutline, doRefresh }
    }
});
</script>

<style scoped lang="scss" src="./BaseLayout.scss"></style>
