
import {defineComponent, ref, toRefs} from 'vue';
import {IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, modalController} from '@ionic/vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import PersonaSkeletonCard from '@/components/skeleton/skeleton-card/SkeletonCard.vue';
import PersonaDocument from '@/components/document/Document.vue';
import {Card} from '@/models/card.model';
import {IAsset} from '@/models/asset.model';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import {IPage} from '@/models/page.model';
import UtilityService from '@/services/utility.service';

export default defineComponent ({
    name: 'Card',
    components: {
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonCardContent,
        PersonaIcon,
        PersonaAsset,
        PersonaDocument,
        PersonaSkeletonCard
    },
    props: {
        card: {
            type: Card,
            required: true
        },
        asSkeleton: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const { card } = toRefs(props);
        // console.log('[Card] card', card.value);

        if (card.value)
        {
            if (card.value.title)
            {
                UtilityService.hyphen(card.value.title).then((title) => {
                    // @ts-ignore
                    card.value.title = title;
                });
            }

            // if (card.value.text)
            // {
            //     UtilityService.hyphen(card.value.text).then((text) => {
            //         // @ts-ignore
            //         card.value.text = text;
            //     });
            // }
        }

        const openModal = async (modalPage: IPage) =>
        {
            const modal = await modalController
            .create({
                component: ModalPage,
                cssClass: 'modal__full',
                componentProps: {
                    page: modalPage
                },
            });
            return modal.present();
        }

        const onClick = () => {
            if (card.value.modal)
            {
                // console.log('[Card] card modal', card.value.modal);
                openModal(card.value.modal);
            }
        }

        return { onClick};
    }
});
