
import {defineComponent} from 'vue';
import {IonItem, IonInput, IonLabel, IonText} from '@ionic/vue';
import {Field, ErrorMessage } from 'vee-validate';

export default defineComponent({
    name: 'FormInput',
    components: {
        Field,
        ErrorMessage,
        IonItem,
        IonLabel,
        IonInput,
        IonText
    },
    props: {
        type: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            required: false
        },
        value: {
            required: true
        },
        disabled: {
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false
        },
    }
});
