
import {IonCol, IonRow, IonText, IonSlides, IonSlide, modalController} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import {Page} from '@/models/page.model';
import ModalPage from '@/components/pages/modal-page/ModalPage.vue';
import {IAsset} from '@/models/asset.model';

export default defineComponent({
    name: 'ImageGrid',
    components: {
        IonCol,
        IonRow,
        IonText,
        PersonaAsset
    },
    props: {
        grid: {
            type: Object,
            required: true
        }
    },
    setup() {
        const modalIsOpen = ref(false);
        async function openModal(asset: IAsset)
        {
            // console.log('[ImageGrid] openModal', asset)

            if (asset.modal)
            {
                const modalPage = asset.modal;

                const modal = await modalController
                .create({
                    component: ModalPage,
                    cssClass: 'modal__full',
                    componentProps: {
                        page: modalPage
                    },
                });

                modal.onWillDismiss().then(
                    () => {
                        modalIsOpen.value = !modalIsOpen.value;
                    }
                )

                return modal.present().then(
                    () => {
                        modalIsOpen.value = !modalIsOpen.value;
                    }
                )
            }
        }
        // const map = AssetService.buildMap(page, openModal);

        return {  modalIsOpen, openModal }
    }
});
