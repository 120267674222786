
import {defineComponent} from 'vue';
import {IonItem, IonInput, IonLabel, IonText} from '@ionic/vue';
import { Field, ErrorMessage } from 'vee-validate';
import { useField } from "vee-validate";

export default defineComponent({
    name: 'FormCheckbox',
    components: {
        Field,
        ErrorMessage,
        IonItem,
        IonLabel,
        IonInput,
        IonText
    },
    props: {
        required: {
            type: Boolean,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        }
    },
    // eslint-disable-next-line vue/no-setup-props-destructure
    setup({ name, value }) {
        const { checked, handleChange } = useField(name, undefined, {
            type: 'checkbox',
            checkedValue: value,
        });

        return { checked, handleChange };
    }
});
