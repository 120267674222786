
import {defineComponent, ref} from 'vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import StorageService from '@/services/storage.service';
import {STORAGE_KEYS} from '@/models/app.model';
import MenuService from '@/services/menu.service';

export default defineComponent({
    name: 'Brand',
    components: {
        PersonaAsset
    },
    setup()
    {
        const reload = () => {
            MenuService.reload(false);
        }

        const app = ref(null);
        StorageService.get(STORAGE_KEYS.APP).then((data) => {
            app.value = data;
        });

        return { reload, app };
    }
});
